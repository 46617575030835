import anime from 'animejs';
import EventEmitter from 'eventemitter3';
import { app, appUtils, YOUTUBE_IMG_LINK } from '..';
import { TypeDataGalleryItem } from '../data';
import Swiper, { SwipeDirs, SwiperEventObj, SwiperEvents } from './swiper';

type GalleryAddItemsParams = {
    prefix: string,
    items: TypeDataGalleryItem[],
}

export enum GalleryEvents {
    None = "none",
    IndexChanged = "indexchanged",
}

export default class Gallery extends EventEmitter<GalleryEvents> {

    private _cont: HTMLElement;
    private _ul: HTMLElement;
    private _pager?: HTMLElement;
    private _pagerList: NodeListOf<HTMLElement>;
    private _pagerSpan?: HTMLSpanElement;
    private _currentIndex: number = 0;
    private _prevIndex: number = -1;
    private _animating: boolean;
    private _swiper: Swiper;
    private _enabled: boolean = true;

    customData?: any;

    get cont() { return this._cont; }
    get index() { return this._currentIndex; }
    get prevIndex() { return this._prevIndex; }
    get enabled() { return this._enabled; }
    set enabled(v: boolean) {
        this._enabled = v;
        if (!this._enabled)
            this._stopVideos();
    }

    constructor(cont: HTMLElement, items?: GalleryAddItemsParams, customData?: object) {
        super();

        this.customData = customData;
        this._cont = cont;
        this._ul = this._cont.querySelector(":scope>ul")!;
        this._pager = this._cont.querySelector(".pager")!;

        (this._swiper = new Swiper(this._cont)).
            on(SwiperEvents.SwipeEnd, e => {
                switch ((e as SwiperEventObj).dir) {
                    case SwipeDirs.Left: this._setIndex(this._currentIndex - 1); break;
                    case SwipeDirs.Right: this._setIndex(this._currentIndex + 1); break;
                }
            });

        this._addItems(items);
        this._checkItems();
        this._initPager();
    }

    private _addItems(items?: GalleryAddItemsParams) {
        if (!items)
            return;

        this._ul.innerHTML = "";
        items.items.forEach((item, index) => {
            const li = document.createElement("li");
            if (item.type == "youtube")
                li.innerHTML = `<img src="${YOUTUBE_IMG_LINK + item.source}/maxresdefault.jpg" />`;
            else
                li.innerHTML = `<img src="${items.prefix + item.source}" />`;

            li.dataset['index'] = index.toString();
            li.dataset['source'] = item.source;
            li.dataset['type'] = item.type || "image";
            this._ul.appendChild(li);
        });
    }

    private _checkItems() {
        this._pagerList = this._ul.querySelectorAll(":scope>li");

        this._ul.querySelectorAll<HTMLElement>(`:scope>li[data-type="video"], :scope>li[data-type="youtube"]`).forEach(item => {
            const btn = app.cloneTemplate("button-video-play") as HTMLButtonElement;
            btn.addEventListener("click", () => this._playVideo(btn, item));
            item.appendChild(btn);
        });
    }

    private _initPager() {

        if (this._pagerList.length < 2 || !this._pager) {
            if (this._pager)
                this._pager.style.display = "none";
            this._setIndex(0, true);
            return;
        }

        this._pagerSpan = this._pager.querySelector(":scope>span")!;

        const svgLeft = this._pager.querySelector("svg.left") as HTMLElement,
            svgRight = this._pager.querySelector("svg:not(.left)") as HTMLElement;

        this._setIndex(0, true);

        svgRight.addEventListener("click", () => this._setIndex(this._currentIndex + 1));
        svgLeft.addEventListener("click", () => this._setIndex(this._currentIndex - 1));
    }

    setIndex(index: number) {
        this._setIndex(index);
    }

    private _setIndex(index: number, force: boolean = false) {
        if (this._animating)
            return;

        const newIndex = appUtils.clamp(index, 0, this._pagerList.length - 1);
        if (this._currentIndex == newIndex && !force)
            return;

        this._stopVideos();

        this._prevIndex = this._currentIndex;

        this._currentIndex = newIndex;
        this._pagerList[this._currentIndex].style.display = "block";

        if (this._prevIndex != this._currentIndex) {
            this._pagerList[this._prevIndex].style.position = "absolute";
        }

        const dir = this._prevIndex - newIndex < 0 ? -1 : 1;

        if (this._pagerSpan)
            this._pagerSpan.innerText = (this._currentIndex + 1) + " / " + this._pagerList.length;


        if (!force) {
            this._animating = true;
            anime({
                targets: this._pagerList[this._prevIndex],
                translateX: dir * 50 + "%", opacity: 0,
                duration: 400, easing: "easeInOutQuad",
                complete: () => {
                    this._pagerList[this._prevIndex].style.display = "none";
                    this._pagerList[this._prevIndex].style.position = "";
                },
            });
            anime({
                targets: this._pagerList[this._currentIndex],
                translateX: [dir * -50 + "%", 0], opacity: [0, 1],
                duration: 400, easing: "easeInOutQuad",
                complete: () => this._animating = false,
            });
        }

        this.emit(GalleryEvents.IndexChanged);
    }

    private _playVideo(btn: HTMLButtonElement, item: HTMLElement) {
        const source = item.dataset["source"]!,
            type = item.dataset["type"];

        if (type == "youtube") {
            const iframe = item.querySelector<HTMLIFrameElement>(":scope>iframe") || document.createElement("iframe");
            iframe.src = `https://www.youtube.com/embed/${source}?controls=0&autoplay=1`;
            iframe.allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
            iframe.allowFullscreen = true;

            item.insertBefore(iframe, item.lastElementChild);
            item.classList.add("playing");
        } else {
            const video = item.querySelector<HTMLVideoElement>(":scope>video") || document.createElement("video");
            video.src = source;

            if (!video.parentElement) {
                video.loop = item.dataset["loop"] == "true";
                video.addEventListener("pause", () => item.classList.remove("playing"));
                video.addEventListener("playing", () => item.classList.add("playing"));
                item.insertBefore(video, item.lastElementChild);
                video.play();
            } else {
                video.play();
            }
        }
    }

    private _stopVideos() {
        const item = this._pagerList[this._currentIndex],
            type = item.dataset["type"];

        if (type == "youtube") {
            const iframe = item.querySelector<HTMLVideoElement>(":scope>iframe");
            item.classList.remove("playing");
            if (iframe) item.removeChild(iframe);
        } else {
            const video = item.querySelector<HTMLVideoElement>(":scope>video");
            if (video) {
                video.pause();
                video.currentTime = 0;
            }
        }
    }
}