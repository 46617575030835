import EventEmitter from "eventemitter3";
import { appUtils, IPoint } from "..";

export enum SwiperEvents {
    None = "none",
    Swiping = "swiping",
    SwipeStart = "swipestart",
    SwipeEnd = "swipeend",
}

export enum SwipeDirs {
    None = "none",
    Left = "left",
    Right = "right",
    Up = "up",
    Down = "down",
}

export type SwiperEventObj = {
    event: SwiperEvents,
    dir: SwipeDirs;
    velocity: IPoint;
    diff: IPoint;
    current: IPoint;
}

export type SwiperConfig = { threshold?: number, cursorHand?: boolean, mouseLeaveEvent?: boolean };

export default class Swiper extends EventEmitter<SwiperEvents>{
    private _target: HTMLElement;
    private _events: { [name: string]: (this: HTMLElement | Window, ev: MouseEvent | TouchEvent) => void } = {};
    private _mouseDown: any;
    private _config: SwiperConfig;
    private _prevMouse: IPoint;
    private _enabled: boolean;

    get enabled() { return this._enabled; }
    set enabled(v: boolean) {
        this._enabled = v;
        if (this._enabled)
            this._target.classList.add("swiper-grab");
        else
            this._target.classList.remove("swiper-grab");
    }

    constructor(target: HTMLElement, config?: SwiperConfig) {
        super();

        this._target = target;
        this._config = { threshold: 50, cursorHand: true, mouseLeaveEvent: true, ...config };
        this._setEvents();
        this.enabled = true;
    }

    destroy() {
        this.enabled = false;
        this._setEvents(true);
    }

    private _setEvents(remove: boolean = false) {
        if (remove) {
            this._target.removeEventListener("mousedown", this._events["mousedown"]);
            this._target.removeEventListener("touchstart", this._events["touchstart"]);
            window.removeEventListener("mouseup", this._events["mouseup"]);
            window.removeEventListener("touchend", this._events["touchend"]);
            window.removeEventListener("touchcancel", this._events["touchcancel"]);

            if (this._config.mouseLeaveEvent)
                document.body.removeEventListener("mouseleave", this._events["mouseleave"]);

            window.removeEventListener("mousemove", this._events["mousemove"]);
            window.removeEventListener("touchmove", this._events["touchmove"]);
        } else {
            this._target.addEventListener("mousedown", this._events["mousedown"] = this._onMouseDown.bind(this));
            this._target.addEventListener("touchstart", this._events["touchstart"] = this._onMouseDown.bind(this));
            window.addEventListener("mouseup", this._events["mouseup"] = this._onMouseUp.bind(this));
            window.addEventListener("touchend", this._events["touchend"] = this._onMouseUp.bind(this));
            window.addEventListener("touchcancel", this._events["touchcancel"] = this._onMouseUp.bind(this));

            if (this._config.mouseLeaveEvent)
                document.body.addEventListener("mouseleave", this._events["mouseleave"] = this._onMouseUp.bind(this));

            window.addEventListener("mousemove", this._events["mousemove"] = this._onMouseMove.bind(this));
            window.addEventListener("touchmove", this._events["touchmove"] = this._onMouseMove.bind(this));
        }
    }

    private _onMouseDown(e: MouseEvent | TouchEvent) {
        if (!this.enabled)
            return;

        if (this._config.cursorHand == true)
            this._target.style.cursor = "grabbing";

        this._prevMouse = this._mouseDown = appUtils.mouseTouchXY(e);
        this.emit(SwiperEvents.SwipeStart, this._getEventObj(SwiperEvents.SwipeStart, this._mouseDown, this._prevMouse, this._mouseDown));

        if (e instanceof MouseEvent)
            e.preventDefault();
    }

    private _onMouseMove(e: MouseEvent | TouchEvent) {
        if (!this._mouseDown)
            return;

        const eventObj = this._getEventObj(SwiperEvents.Swiping, this._mouseDown, this._prevMouse, appUtils.mouseTouchXY(e));
        this._prevMouse = eventObj.current;
        this.emit(SwiperEvents.Swiping, eventObj);
    }

    private _onMouseUp(e: MouseEvent | TouchEvent) {
        if (this._mouseDown)
            this.emit(SwiperEvents.SwipeEnd,
                this._getEventObj(SwiperEvents.SwipeEnd, this._mouseDown, this._prevMouse, appUtils.mouseTouchXY(e)));

        this._mouseDown = null;

        if (this._config.cursorHand == true)
            this._target.style.cursor = "";
    }

    private _getEventObj(event: SwiperEvents, down: IPoint, prev: IPoint, current: IPoint): SwiperEventObj {
        const diff = { x: current.x - this._mouseDown.x, y: current.y - this._mouseDown.y },
            diffAbs = { x: Math.abs(diff.x), y: Math.abs(diff.y) },
            velocity = { x: current.x - prev.x, y: current.y - prev.y };

        let dir = SwipeDirs.None;

        if ((diffAbs.x > this._config.threshold || diffAbs.y > this._config.threshold)) {
            if (diffAbs.x > diffAbs.y) {
                if (diff.x > 0)
                    dir = SwipeDirs.Left;
                else
                    dir = SwipeDirs.Right;
            } else {
                if (diff.y > 0)
                    dir = SwipeDirs.Up;
                else
                    dir = SwipeDirs.Down;
            }
        }

        return { event, dir, diff, current, velocity };
    }
}