import { DataCarList } from "../data";

const CAR_PAGE_KEY = 'modeller'

export default class URLParams {
    private _searchParams = new URLSearchParams(window.location.search);

    constructor() {
    }

    private _replaceHistory(path?: string) {
        window.history.replaceState(window.history.state, '', path || '/')
    }

    private _checkPath(path: string) {
        return window.location.pathname.indexOf(path) == 0;
    }

    setSearchParam(name: string, value?: string) {
        if (value == undefined || value.length < 1)
            this._searchParams.delete(name);
        else
            this._searchParams.set(name, value);
        window.history.replaceState({}, document.title, "?" + this._searchParams.toString());
    }
    get carPage() {
        const carData = DataCarList.find(v => this._checkPath(`/${CAR_PAGE_KEY}/${v.urlPageId}`));
        return carData?.urlPageId;
    }
    setCarPage(value?: string) {
        this._replaceHistory(`/${CAR_PAGE_KEY}/${value}`);
    }
    clearCarPage(value?: string) {
        this._replaceHistory();
    }

    /* get carPage() {
        const val = this._searchParams.get(CAR_PAGE_KEY);
        if (DataCarList.some(v => val == v.urlPageId))
            return val;

        return undefined;
    }
    setCarPage(value?: string) { this.setSearchParam(CAR_PAGE_KEY, value); }
    clearCarPage(value?: string) { this.setSearchParam(CAR_PAGE_KEY); } */

    /* get carPage() {
        for (const key of this._searchParams.keys())
            if (DataCarList.some(v => key == v.urlPageId))
                return key;

        return undefined;
    }
    setCarPage(car: string) {
        this.clearCarPage();
        window.history.replaceState({}, document.title, "?" + car +
            (this._searchParams.toString().length ? '&' : '') + this._searchParams.toString());
    }
    clearCarPage() {
        DataCarList.forEach(v => this._searchParams.delete(v.urlPageId))
        window.history.replaceState({}, document.title, "?" + this._searchParams.toString());
    } */
} 