import { IDataCar } from ".";

export default {
    id: "car_208",
    urlPageId: "208",
    title: "PEUGEOT 208",
    shortTitle: "208",
    colors: [
        { colorHex: "#282828", title: "Siyah (İnci)", dir: "siyah"},
        { colorHex: "#f7d471", title: "Sarı (Agueda)", dir: "sari", featured: true },
        { colorHex: "#ababab", title: "Gri (Tekno)", dir: "gri_tekno" },
        { colorHex: "#404040", title: "Gri (Selenyum)", dir: "gri" },
        { colorHex: "#832223", title: "Kırmızı (Elixir)", dir: "kirmizi" },
        { colorHex: "#3b84d5", title: "Mavi (Vertigo)", dir: "mavi" },
        { colorHex: "#f1f1f1", title: "Beyaz (Alp)", dir: "beyaz" },
    ],
    featuredPinFrameIndex: 1,
    infoLink: "https://www.peugeot.com.tr/peugeot-modelleri/208.html",
    otherPackagesLink: "https://www.peugeot.com.tr/peugeot-modelleri/208.html",
    broshureLink: "https://www.peugeot.com.tr/content/dam/peugeot/turkey/b2c/bro%C5%9F%C3%BCrler/2024/Mart_0018_208_ModelBrosuru.pdf",
    appointmentLink: 'yeni208/hb',
    galleryPhotos: [
        { source: "gallery_0.jpg" }, { source: "gallery_1.jpg" }, { source: "gallery_2.jpg" }, { source: "gallery_3.jpg" },
        { source: "gallery_4.jpg" }, { source: "gallery_5.jpg" }, { source: "gallery_6.jpg" }, { source: "gallery_7.jpg" },
        { source: "gallery_8.jpg" }, { source: "gallery_9.jpg" }, { source: "gallery_10.jpg" },
    ],
    videos: [
        { title: "Zamanın Ötesinde Olanlar İçin", type: "youtube", source: "WXOOGVyatLw" },
        { title: "Zamanın Ötesinde Olanlar İçin 2", type: "youtube", source: "5Y2S89mpApM" },
        { title: "Zamanın Ötesinde Olanlar İçin 3", type: "youtube", source: "N1falJmQKxk" },
        { title: "Zamanın Ötesinde Olanlar İçin 4", type: "youtube", source: "NKRD-5kpW7Y" },
    ],
    interiorImageNames: [
        "interior_0", "interior_1", "interior_2", "interior_3", "interior_4", "interior_5",
        // tüm renkler için sonuna {_color} koy.
    ],
    pinsInterior: [
        {
            id: "cockpit", coords: { x: 64, y: -15 },
            speechKeywords: "gösterge paneli, gösterge, panel, kokpit, i cockpit, peugeot 3D i cockpit, digital gösterge, dijital ekran, 3D ekran, 3D gösterge, kadran, navigasyon, sürüş modları, adaptif cruise control, mesafe ayarı",
        },
        {
            id: "screen", coords: { x: 83, y: -18 },
            speechKeywords: "start stop buton, start, stop, buton, dokunmatik ekran, dokunmatik, kapasitif dokunmatik ekran, kapasitif, klima, radyo, ortam rengi, mirror screen, apple car play, apple car, bluetooth, wi-fi",
        }, 
        {
            id: "interiordesign", coords: { x: 90, y: 0 },
            speechKeywords: "orta konsol, konsol, şanzıman, quickshift, kablosuz şarj, şarj, vites",
        },
        {
            id: "interiordesign", coords: { x: 90, y: -25 },
            speechKeywords: "orta konsol, konsol, şanzıman, quickshift, kablosuz şarj, şarj, vites",
        },
        {
            id: "interiordesign", coords: { x: 90, y: 85 },
            speechKeywords: "orta konsol, konsol, şanzıman, quickshift, kablosuz şarj, şarj, vites",
        },
        {
            id: "midconsole", coords: { x: 90, y: -75 },
            speechKeywords: "orta konsol, konsol, şanzıman, quickshift, kablosuz şarj, şarj, vites",
        },
        {
            id: "mop", coords: { x: 34, y: -65 },
            speechKeywords: "orta konsol, konsol, şanzıman, quickshift, kablosuz şarj, şarj, vites",
        },
        {
            id: "steeringwheel", coords: { x: 64, y: -15 },
            speechKeywords: "deri kaplı direksiyon ve direksiyon üzeri kontrol butonları",
        },
    ],
    pinFrames: [
        {index: 0, pins: [{ id: "signitures", posXPerc: 40.84, posYPerc: 57.42 },]},
        {index: 1, pins: [{ id: "signitures", posXPerc: 51.83, posYPerc: 58.4 },]},
        {index: 2, pins: [{ id: "signitures", posXPerc: 60.28, posYPerc: 58.4 },]},
        {index: 3, pins: [{ id: "signitures", posXPerc: 67.06, posYPerc: 56.91 },]},
        {index: 4, pins: [{ id: "signitures", posXPerc: 73.5, posYPerc: 57.07 },]},
        {index: 5, pins: [{ id: "signitures", posXPerc: 78.72, posYPerc: 56.91 },]},
        {index: 6, pins: [{ id: "signitures", posXPerc: 83.28, posYPerc: 56.57 },]},
        {index: 7, pins: [{ id: "signitures", posXPerc: 84.72, posYPerc: 55.91 },]},
        {index: 8, pins: [{ id: "signitures", posXPerc: 85.94, posYPerc: 55.74 },]},
        {index: 9, pins: [{ id: "drivesupport", posXPerc: 17.17, posYPerc: 45.09 },{ id: "signitures", posXPerc: 85.5, posYPerc: 55.24 },]},
        {index: 10, pins: [{ id: "drivesupport", posXPerc: 16.83, posYPerc: 47.59 },{ id: "signitures", posXPerc: 84.61, posYPerc: 54.41 },]},
        {index: 11, pins: [{ id: "drivesupport", posXPerc: 17.72, posYPerc: 46.42 },]},
        {index: 12, pins: [{ id: "drivesupport", posXPerc: 22.39, posYPerc: 45.09 },]},
        {index: 13, pins: [{ id: "drivesupport", posXPerc: 26.17, posYPerc: 46.42 },]},
        {index: 14, pins: [{ id: "drivesupport", posXPerc: 31.5, posYPerc: 46.76 },]},
        {index: 15, pins: [{ id: "drivesupport", posXPerc: 38.72, posYPerc: 46.09 },]},
        {index: 16, pins: [{ id: "drivesupport", posXPerc: 46.61, posYPerc: 46.09 },]},
        {index: 17, pins: [{ id: "drivesupport", posXPerc: 55.17, posYPerc: 46.59 },]},
        {index: 18, pins: [{ id: "drivesupport", posXPerc: 58.83, posYPerc: 45.76 },]},
        {index: 19, pins: [{ id: "drivesupport", posXPerc: 63.94, posYPerc: 45.76 },]},
        {index: 20, pins: [{ id: "drivesupport", posXPerc: 67.06, posYPerc: 45.42 },]},
        {index: 21, pins: [{ id: "drivesupport", posXPerc: 71.94, posYPerc: 44.43 },]},
        {index: 22, pins: [{ id: "drivesupport", posXPerc: 75.94, posYPerc: 43.43 },]},
        {index: 23, pins: [{ id: "drivesupport", posXPerc: 77.5, posYPerc: 43.59 },]},
        {index: 24, pins: [{ id: "drivesupport", posXPerc: 81.17, posYPerc: 45.09 },]},
        {index: 25, pins: [{ id: "drivesupport", posXPerc: 82.83, posYPerc: 45.09 },]},
        {index: 26, pins: [{ id: "drivesupport", posXPerc: 83.06, posYPerc: 44.43 },]},
        {index: 27, pins: [{ id: "signitures", posXPerc: 14.17, posYPerc: 56.07 },{ id: "drivesupport", posXPerc: 83.83, posYPerc: 47.25 },]},
        {index: 28, pins: [{ id: "signitures", posXPerc: 14.28, posYPerc: 57.74 },{ id: "drivesupport", posXPerc: 81.5, posYPerc: 44.26 },]},
        {index: 29, pins: [{ id: "signitures", posXPerc: 14.72, posYPerc: 58.4 },]},
        {index: 30, pins: [{ id: "signitures", posXPerc: 18.28, posYPerc: 58.24 },]},
        {index: 31, pins: [{ id: "signitures", posXPerc: 23.06, posYPerc: 58.24 },]},
        {index: 32, pins: [{ id: "signitures", posXPerc: 26.83, posYPerc: 57.57 },]},
        {index: 33, pins: [{ id: "signitures", posXPerc: 34.5, posYPerc: 57.24 },]},
        {index: 34, pins: [{ id: "signitures", posXPerc: 40.61, posYPerc: 56.41 },]},
        {index: 35, pins: [{ id: "signitures", posXPerc: 49.28, posYPerc: 57.9 },]},
        ],
} as IDataCar;