import { IDataCar } from ".";

export default {
    id: "car_508",
    urlPageId: "508",
    title: "PEUGEOT 508",
    shortTitle: "508",
    colors: [
        { colorHex: "#7b7d84", title: "Gri (Selenyum)", dir: "gri", featured: true },
        // TODO release comment below
        { colorHex: "#BEBEBE", title: "Gri (Tekno)", dir: "gri_tekno" },
        { colorHex: "#4A4B4E", title: "Gri (Titanyum)", dir: "gri_titanyum" },
        { colorHex: "#256e91", title: "Mavi (Eclipse)", dir: "mavi" },
        { colorHex: "#851a26", title: "Kırmızı (Elixir)", dir: "kirmizi" },
        { colorHex: "#222222", title: "Siyah (İnci)", dir: "siyah" },
        { colorHex: "#D8DDE9", title: "Beyaz (Okenit)", dir: "beyaz" },
    ],
    infoLink: "https://www.peugeot.com.tr/peugeot-modelleri/508.html",
    otherPackagesLink: "https://www.peugeot.com.tr/peugeot-modelleri/508.html",
    broshureLink: "https://www.peugeot.com.tr/content/dam/peugeot/turkey/b2c/bro%C5%9F%C3%BCrler/2023/temmuz/yeni-508/508_ModelBrosuru.pdf ",
    appointmentLink: '508',
    galleryPhotos: [
        { source: "gallery_0.jpg" }, { source: "gallery_1.jpg" }, { source: "gallery_2.jpg" },
        { source: "gallery_3.jpg" }, { source: "gallery_4.jpg" }, { source: "gallery_5.jpg" },
        { source: "gallery_6.jpg" }, { source: "gallery_7.jpg" }, { source: "gallery_8.jpg" },
        { source: "gallery_9.jpg" }, { source: "gallery_10.jpg" },
    ],
    videos: [
        { title: "Yeni PEUGEOT 508 Aksesuarlarını Tanıyalım", type: "youtube", source: "cmESq9LvOjs" },
    ],
    interiorImageNames: [
        "interior_0", "interior_1", "interior_2", "interior_3", "interior_4", "interior_5",
    ],
    pinsInterior: [
        { id: "midconsole", coords: { x: 96.49, y: -34.63 }, },
        { id: "wheel", coords: { x: 70.1, y: -13.87 }, },
        { id: "cockpit", coords: { x: 80.08, y: -1.86 }, },
        { id: "screen", coords: { x: 94.93, y: -10.05 }, },
        { id: "rearmirror", coords: { x: 94.74, y: 11.49 }, },
        { id: "speakers", coords: { x: 131.5, y: -5.49 }, },
        { id: "seats", coords: { x: 56.55, y: -48.47 }, },
        { id: "sunroof", coords: { x: 96.75, y: 59.24 }, },
    ],
    featuredPinFrameIndex: 35,
    pinFrames: [
        { index: 0, pins: [{ id: "lights", posXPerc: 45.06, posYPerc: 57.65 }, { id: "frontgrid", posXPerc: 63.61, posYPerc: 62.48 },] },
        { index: 1, pins: [{ id: "lights", posXPerc: 54.61, posYPerc: 58.67 }, { id: "frontgrid", posXPerc: 71.83, posYPerc: 63.31 },] },
        { index: 2, pins: [{ id: "lights", posXPerc: 62.83, posYPerc: 58.99 }, { id: "frontgrid", posXPerc: 78.39, posYPerc: 62.15 },] },
        { index: 3, pins: [{ id: "lights", posXPerc: 70.94, posYPerc: 57.82 }, { id: "frontgrid", posXPerc: 84.5, posYPerc: 61.49 },] },
        { index: 4, pins: [{ id: "lights", posXPerc: 74.83, posYPerc: 58.82 }, { id: "frontgrid", posXPerc: 87.39, posYPerc: 60.98 },] },
        { index: 5, pins: [{ id: "lights", posXPerc: 80.39, posYPerc: 57.99 }, { id: "frontgrid", posXPerc: 90.61, posYPerc: 60.82 },] },
        { index: 6, pins: [{ id: "lights", posXPerc: 84.94, posYPerc: 57.65 },] },
        { index: 7, pins: [{ id: "lights", posXPerc: 88.17, posYPerc: 57.15 }, { id: "backlights", posXPerc: 7.83, posYPerc: 49.17 },] },
        { index: 8, pins: [{ id: "backlights", posXPerc: 7.39, posYPerc: 49.67 }, { id: "lights", posXPerc: 89.94, posYPerc: 56.66 },] },
        { index: 9, pins: [{ id: "backlights", posXPerc: 9.28, posYPerc: 49.67 },] },
        { index: 10, pins: [{ id: "backlights", posXPerc: 12.28, posYPerc: 49.67 },] },
        { index: 11, pins: [{ id: "trunk", posXPerc: 7.5, posYPerc: 51.84 }, { id: "backlights", posXPerc: 16.94, posYPerc: 50.33 },] },
        { index: 12, pins: [{ id: "backlights", posXPerc: 22.72, posYPerc: 50.17 }, { id: "trunk", posXPerc: 10.17, posYPerc: 54.01 },] },
        { index: 13, pins: [{ id: "trunk", posXPerc: 14.06, posYPerc: 55.32 }, { id: "backlights", posXPerc: 29.5, posYPerc: 50.5 },] },
        { index: 14, pins: [{ id: "trunk", posXPerc: 21.17, posYPerc: 55.49 }, { id: "backlights", posXPerc: 38.17, posYPerc: 50.84 },] },
        { index: 15, pins: [{ id: "backlights", posXPerc: 47.39, posYPerc: 50.67 }, { id: "trunk", posXPerc: 29.61, posYPerc: 54.84 },] },
        { index: 16, pins: [{ id: "trunk", posXPerc: 39.06, posYPerc: 56.66 }, { id: "backlights", posXPerc: 56.61, posYPerc: 51 },] },
        { index: 17, pins: [{ id: "trunk", posXPerc: 48.94, posYPerc: 55.32 }, { id: "backlights", posXPerc: 65.5, posYPerc: 51 },] },
        { index: 18, pins: [{ id: "backlights", posXPerc: 43.28, posYPerc: 50.83 }, { id: "trunk", posXPerc: 59.61, posYPerc: 55.99 },] },
        { index: 19, pins: [{ id: "trunk", posXPerc: 68.72, posYPerc: 56.32 }, { id: "backlights", posXPerc: 52.94, posYPerc: 50.83 },] },
        { index: 20, pins: [{ id: "backlights", posXPerc: 63.17, posYPerc: 51 }, { id: "trunk", posXPerc: 76.94, posYPerc: 55.99 },] },
        { index: 21, pins: [{ id: "trunk", posXPerc: 84.94, posYPerc: 56.66 }, { id: "backlights", posXPerc: 72.06, posYPerc: 51.33 },] },
        { index: 22, pins: [{ id: "trunk", posXPerc: 86.5, posYPerc: 56.16 }, { id: "backlights", posXPerc: 74.39, posYPerc: 51.33 },] },
        { index: 23, pins: [{ id: "trunk", posXPerc: 90.06, posYPerc: 56.16 }, { id: "backlights", posXPerc: 80.61, posYPerc: 51.5 },] },
        { index: 24, pins: [{ id: "backlights", posXPerc: 86.39, posYPerc: 51 }, { id: "trunk", posXPerc: 92.5, posYPerc: 55.49 },] },
        { index: 25, pins: [{ id: "backlights", posXPerc: 90.06, posYPerc: 51.5 }, { id: "lights", posXPerc: 11.06, posYPerc: 53.83 },] },
        { index: 26, pins: [{ id: "lights", posXPerc: 9.06, posYPerc: 55.16 }, { id: "backlights", posXPerc: 91.5, posYPerc: 51.33 },] },
        { index: 27, pins: [{ id: "lights", posXPerc: 10.06, posYPerc: 55.82 }, { id: "backlights", posXPerc: 91.83, posYPerc: 51.16 },] },
        { index: 28, pins: [{ id: "lights", posXPerc: 11.78, posYPerc: 57.18 },] },
        { index: 29, pins: [{ id: "lights", posXPerc: 16.17, posYPerc: 56.66 }, { id: "frontgrid", posXPerc: 7.5, posYPerc: 58.49 },] },
        { index: 30, pins: [{ id: "frontgrid", posXPerc: 10.06, posYPerc: 59.65 }, { id: "lights", posXPerc: 21.94, posYPerc: 57.32 },] },
        { index: 31, pins: [{ id: "lights", posXPerc: 28.83, posYPerc: 58.51 }, { id: "frontgrid", posXPerc: 14.28, posYPerc: 60.32 },] },
        { index: 32, pins: [{ id: "lights", posXPerc: 36.94, posYPerc: 58.32 }, { id: "frontgrid", posXPerc: 20.72, posYPerc: 61.31 },] },
        { index: 33, pins: [{ id: "frontgrid", posXPerc: 28.5, posYPerc: 61.5 }, { id: "lights", posXPerc: 45.61, posYPerc: 59.32 },] },
        { index: 34, pins: [{ id: "lights", posXPerc: 55.28, posYPerc: 59.32 }, { id: "frontgrid", posXPerc: 37.61, posYPerc: 62.65 },] },
        { index: 35, pins: [{ id: "lights", posXPerc: 67.94, posYPerc: 58.65 }, { id: "frontgrid", posXPerc: 51.72, posYPerc: 63.48 },] },
    ]
} as IDataCar;