import anime from "animejs";
import { app } from "..";
import UIView from "./uiView";
import { UIViewType } from "./uiViews";

export default class UIMenuViewGeneralInfo extends UIView {
    private _ul: HTMLUListElement;

    constructor(container?: HTMLElement, type?: UIViewType) {
        super(container || app.ui.views.cont.querySelector(".menu-general-info")!, type || UIViewType.GeneralInfo);

        this._ul = this._cont.querySelector(":scope > ul") as HTMLUListElement;
        this._ul.querySelectorAll<HTMLElement>(':scope>li').forEach((item) => {
            item.addEventListener('click', () => app.ui.carSubMenu.onBtnClick(item))
        })
    }

    show(complete: Function) {
        super.show(complete);
    }

    protected _onShow(complete: Function) {
        super._onShow(complete, 250);

        anime({
            targets: this._ul.children,
            opacity: [0, 1], translateY: ["15%", 0],
            duration: 350, easing: "easeOutQuad",
            delay: anime.stagger(40, { start: 100, from: "last" })
        });

    }
}