import { IDataCar } from ".";

export default {
    id: "car_5008",
    urlPageId: "5008",
    title: "PEUGEOT SUV 5008",
    shortTitle: "5008",
    colors: [
        { colorHex: "#48596a", title: "Ingora Mavi", dir: "mavi_ingora", featured: true },
        // { colorHex: "#F3F3F5", title: "Mavi (Obession)", dir: "mavi", },
        // { colorHex: "#f1f1f1", title: "Beyaz (Okenit)", dir: "beyaz" },
        // { colorHex: "#E6E7E9", title: "Gri (Tekno)", dir: "gri" },
        // { colorHex: "#292a2a", title: "Gri (Titan)", dir: "titan" },
        // { colorHex: "#000000", title: "Siyah (İnci)", dir: "siyah" },
    ],
    infoLink: "https://www.peugeot.com.tr/peugeot-modelleri/5008.html",
    otherPackagesLink: "https://www.peugeot.com.tr/peugeot-modelleri/5008.html",
    broshureLink: "https://www.peugeot.com.tr/content/dam/peugeot/turkey/b2c/bro%C5%9F%C3%BCrler/2024/kasim/Yeni_5008_E-5008_Teknik_Brosur.pdf",
    appointmentLink: 'yeni5008/suv',
    featuredPinFrameIndex: 1,
    galleryPhotos: [
        { source: "gallery_0.jpg" }, { source: "gallery_1.jpg" }, { source: "gallery_2.jpg" }, { source: "gallery_3.jpg" },
        { source: "gallery_4.jpg" }, { source: "gallery_5.jpg" }, { source: "gallery_6.jpg" }, { source: "gallery_7.jpg" },
        { source: "gallery_8.jpg" },
    ],
    videos: [
        { title: "Baştan Sona Göz Alıcı", type: "youtube", source: "ZepBgFLcdF0" },
    ],
    interiorImageNames: [
        "interior_0", "interior_1", "interior_2", "interior_3", "interior_4", "interior_5",
    ],
    pinsInterior: [
        {
            id: "sunroof-i", coords: { x: -88, y: 33 },
            speechKeywords: "cam tavan, iç tavan, tavan, sunroof",
        }, {
            id: "steering", coords: { x: 83, y: -22 },
            speechKeywords: "gösterge paneli, gösterge, panel, kokpit, i cockpit, peugeot 3D i cockpit, digital gösterge, dijital ekran, 3D ekran, 3D gösterge, kadran, navigasyon, sürüş modları, adaptif cruise control, mesafe ayarı",
        }, {
            id: "screen", coords: { x: 90, y: -14 },
            speechKeywords: "start stop buton, start, stop, buton, dokunmatik ekran, dokunmatik, kapasitif dokunmatik ekran, kapasitif, klima, radyo, ortam rengi, mirror screen, apple car play, apple car, bluetooth, wi-fi",
        }, {
            id: "cleancabin", coords: { x: 105, y: -18 },
            speechKeywords: "start stop buton, start, stop, buton, dokunmatik ekran, dokunmatik, kapasitif dokunmatik ekran, kapasitif, klima, radyo, ortam rengi, mirror screen, apple car play, apple car, bluetooth, wi-fi",
        }, {
            id: "panoramicscreen", coords: { x: 80, y: -13 },
            speechKeywords: "start stop buton, start, stop, buton, dokunmatik ekran, dokunmatik, kapasitif dokunmatik ekran, kapasitif, klima, radyo, ortam rengi, mirror screen, apple car play, apple car, bluetooth, wi-fi",
        }, {
            id: "e-toggle", coords: { x: 89, y: -20 },
            speechKeywords: "start stop buton, start, stop, buton, dokunmatik ekran, dokunmatik, kapasitif dokunmatik ekran, kapasitif, klima, radyo, ortam rengi, mirror screen, apple car play, apple car, bluetooth, wi-fi",
        }, {
            id: "ambiance", coords: { x: 110, y: -14 },
            speechKeywords: "start stop buton, start, stop, buton, dokunmatik ekran, dokunmatik, kapasitif dokunmatik ekran, kapasitif, klima, radyo, ortam rengi, mirror screen, apple car play, apple car, bluetooth, wi-fi",
        }, {
            id: "i-toggle", coords: { x: 99, y: -23 },
            speechKeywords: "start stop buton, start, stop, buton, dokunmatik ekran, dokunmatik, kapasitif dokunmatik ekran, kapasitif, klima, radyo, ortam rengi, mirror screen, apple car play, apple car, bluetooth, wi-fi",
        }, {
            id: "midconsole", coords: { x: 92, y: -35 },
            speechKeywords: "orta konsol, konsol, şanzıman, quickshift, kablosuz şarj, şarj, vites",
        }, {
            id: "backbuttons", coords: { x: 92, y: -80 },
            speechKeywords: "orta konsol, konsol, şanzıman, quickshift, kablosuz şarj, şarj, vites",
        }, {
            id: "secondaryrow", coords: { x: -50, y: -10 },
            speechKeywords: "usb, usb giriş, usb bağlantı",
        }, {
            id: "thirdrow", coords: { x: -72, y: -5 },
            speechKeywords: "usb, usb giriş, usb bağlantı",
        }, {
            id: "massagebuttons", coords: { x: 54, y: -42 },
            speechKeywords: "masaj, düğme, masaj düğmeleri",
        }, {
            id: "backarmrest", coords: { x: -85, y: -33 },
            speechKeywords: "arka kol dayama",
        }, {
            id: "frontarmrest", coords: { x: 90, y: -58 },
            speechKeywords: "ön kol dayama",
        },
    ],
    pinFrames: [
        { index: 0, pins: [{ id: "lights", posXPerc: 53.11, posYPerc: 52.36 }, { id: "frontdesign", posXPerc: 72.22, posYPerc: 59.21 }, { id: "sunroof", posXPerc: 54.44, posYPerc: 31.18 },] },
        { index: 1, pins: [{ id: "lights", posXPerc: 61, posYPerc: 53.02 }, { id: "frontdesign", posXPerc: 78.11, posYPerc: 61.18 }, { id: "sunroof", posXPerc: 37.33, posYPerc: 31.68 },] },
        { index: 2, pins: [{ id: "lights", posXPerc: 63.11, posYPerc: 50.37 }, { id: "frontdesign", posXPerc: 84.34, posYPerc: 59.54 }, { id: "sunroof", posXPerc: 39.33, posYPerc: 31.34 }, { id: "rim", posXPerc: 52.5, posYPerc: 63.89 },] },
        { index: 3, pins: [{ id: "lights", posXPerc: 68.78, posYPerc: 50.71 }, { id: "frontdesign", posXPerc: 82.55, posYPerc: 58.87 }, { id: "sunroof", posXPerc: 39.56, posYPerc: 31.51 }, { id: "rim", posXPerc: 58.61, posYPerc: 63.24 },] },
        { index: 4, pins: [{ id: "lights", posXPerc: 73.66, posYPerc: 51.37 }, { id: "frontdesign", posXPerc: 86.89, posYPerc: 59.04 }, { id: "sunroof", posXPerc: 44.11, posYPerc: 33.18 },] },
        { index: 5, pins: [{ id: "lights", posXPerc: 79.56, posYPerc: 51.2 },] },
        { index: 6, pins: [{ id: "lights", posXPerc: 84.56, posYPerc: 52.37 },] },
        { index: 7, pins: [{ id: "lights", posXPerc: 87.56, posYPerc: 52.53 },] },
        { index: 8, pins: [{ id: "lights", posXPerc: 87.78, posYPerc: 53.2 }, { id: "backlights", posXPerc: 17.44, posYPerc: 46.18 },] },
        { index: 9, pins: [] },
        { index: 10, pins: [{ id: "backlights", posXPerc: 21.89, posYPerc: 46.19 },] },
        { index: 11, pins: [] },
        { index: 12, pins: [] },
        { index: 13, pins: [{ id: "backdesign", posXPerc: 32.23, posYPerc: 48.05 },] },
        { index: 14, pins: [{ id: "backdesign", posXPerc: 38.89, posYPerc: 47.53 }, { id: "spoiler", posXPerc: 43.61, posYPerc: 32.78 },] },
        { index: 15, pins: [{ id: "backdesign", posXPerc: 47.11, posYPerc: 47.87 }, { id: "spoiler", posXPerc: 49.17, posYPerc: 32.11 },] },
        { index: 16, pins: [{ id: "backdesign", posXPerc: 51, posYPerc: 47.2 }, { id: "trunk", posXPerc: 43.94, posYPerc: 54.27 }, { id: "eletrictrunk", posXPerc: 52.72, posYPerc: 61.56 }, { id: "spoiler", posXPerc: 39.61, posYPerc: 32.28 },] },
        { index: 17, pins: [{ id: "backdesign", posXPerc: 56, posYPerc: 47.2 }, { id: "trunk", posXPerc: 51.83, posYPerc: 55.74 }, { id: "eletrictrunk", posXPerc: 58.5, posYPerc: 61.9 }, { id: "spoiler", posXPerc: 42.72, posYPerc: 32.11 },] },
        { index: 18, pins: [{ id: "backdesign", posXPerc: 61.78, posYPerc: 47.7 }, { id: "spoiler", posXPerc: 49.17, posYPerc: 33.94 },] },
        { index: 19, pins: [{ id: "backdesign", posXPerc: 70.34, posYPerc: 47.21 },] },
        { index: 20, pins: [{ id: "backdesign", posXPerc: 78.89, posYPerc: 47.37 },] },
        { index: 21, pins: [{ id: "backdesign", posXPerc: 83.78, posYPerc: 47.2 },] },
        { index: 22, pins: [{ id: "backdesign", posXPerc: 88, posYPerc: 46.54 },] },
        { index: 23, pins: [{ id: "backdesign", posXPerc: 90.66, posYPerc: 48.03 },] },
        { index: 24, pins: [] },
        { index: 25, pins: [] },
        { index: 26, pins: [] },
        { index: 27, pins: [{ id: "lights", posXPerc: 18.55, posYPerc: 51.53 },] },
        { index: 28, pins: [{ id: "lights", posXPerc: 26, posYPerc: 51.53 }, { id: "rim", posXPerc: 37.17, posYPerc: 65.06 },] },
        { index: 29, pins: [{ id: "lights", posXPerc: 33.89, posYPerc: 50.87 }, { id: "rim", posXPerc: 44.05, posYPerc: 63.08 },] },
        { index: 30, pins: [{ id: "lights", posXPerc: 41.67, posYPerc: 50.04 },] },
        { index: 31, pins: [{ id: "lights", posXPerc: 50.22, posYPerc: 50.2 },] },
        { index: 32, pins: [{ id: "lights", posXPerc: 58.44, posYPerc: 50.54 }, { id: "frontdesign", posXPerc: 31.78, posYPerc: 59.7 }, { id: "sunroof", posXPerc: 50, posYPerc: 33 },] },
        { index: 33, pins: [{ id: "lights", posXPerc: 30.55, posYPerc: 50.04 }, { id: "frontdesign", posXPerc: 41.56, posYPerc: 61.68 }, { id: "sunroof", posXPerc: 50, posYPerc: 33 },] },
        { index: 34, pins: [{ id: "lights", posXPerc: 33.33, posYPerc: 50.71 }, { id: "frontdesign", posXPerc: 52.44, posYPerc: 61.51 }, { id: "sunroof", posXPerc: 61.44, posYPerc: 31.18 },] },
        { index: 35, pins: [{ id: "lights", posXPerc: 69.45, posYPerc: 52.03 }, { id: "frontdesign", posXPerc: 61.89, posYPerc: 61.85 }, { id: "sunroof", posXPerc: 55.89, posYPerc: 31.84 },] },
    ]
} as IDataCar;