import anime from "animejs";
import { app } from "..";
import { UIViewType } from "./uiViews";

export default class UIView {
    protected _cont: HTMLElement;
    protected _type: UIViewType;
    protected _displayStyle: string;
    protected _btnClose: HTMLElement;

    constructor(container: HTMLElement, type: UIViewType, displayStyle: string = "block") {
        this._displayStyle = displayStyle;
        this._cont = container;
        this._type = type;

        if (this._cont.dataset["bgClose"] != null) {
            this._cont.addEventListener("click", e => {
                if (e.target == this._cont)
                    app.ui.views.hideView();
            });
        }

        this._btnClose = this._cont.querySelector(".btn-close")!;
        if (this._btnClose) this._btnClose.addEventListener("click", () => app.ui.views.hideView());
    }

    show(complete: Function) {
        if (this._cont.style.display == this._displayStyle) {
            complete();
            app.ui.views.hideView(this._type);
            return;
        }

        this._onShow(complete);
    }

    protected _onShow(complete: Function, endDelay: number = 0) {
        this._cont.style.display = this._displayStyle;
        anime({
            targets: this._cont, opacity: [0, 1], duration: 350, easing: "linear",
            endDelay, complete: () => complete()
        });
    }

    hide(complete: Function) {
        anime({
            targets: this._cont, opacity: 0, duration: 350, easing: "linear",
            complete: () => {
                this._cont.style.display = "none";
                complete();
            }
        });
    }

    protected _animChildrenShared(targets: any) {
        anime({
            targets: targets,
            opacity: [0, 1], translateY: ["2rem", 0], easing: "linear",
            duration: 400, delay: anime.stagger(100, { start: 200 }),
        });
    }
}