import anime from "animejs";
import { app, STATIC_IMAGE_URL } from "..";
import { UIEvents } from "../ui";
import Gallery from "./gallery";
import UIView from "./uiView";
import { UIViewType } from "./uiViews";

export default class UIViewGallery extends UIView {
    private _title: HTMLElement;
    private _galleryDom: HTMLElement;
    private _gallery: Gallery;

    constructor() {
        super(app.ui.views.cont.querySelector("." + UIViewType.Gallery)!, UIViewType.Gallery, "flex");

        this._title = this._cont.querySelector(":scope>h1")!;
        this._galleryDom = this._cont.querySelector(":scope>div.gallery-module")!;

        app.ui.addListener(UIEvents.CarChanged, () => this._onCarChanged());
    }

    protected _onShow(complete: Function, endDelay?: number): void {
        super._onShow(complete, endDelay);
        this._animChildrenShared(this._cont.querySelectorAll(":scope>*:not(button)"));
    }

    hide(complete: Function): void {
        super.hide(complete);
        this._gallery.enabled = false;
    }

    private _onCarChanged() {
        if (!app.ui.car)
            return;

        this._title.innerText = app.ui.car.data.title;

        this._gallery = new Gallery(this._galleryDom,
            {
                prefix: `${STATIC_IMAGE_URL + app.ui.car.data.id}/`,
                items: app.ui.car.data.galleryPhotos
            });
    }
}