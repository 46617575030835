import anime from "animejs";
import { app } from "..";
import { IUISec } from "../ui";

export default class UISecIntro implements IUISec {
    private _cont: HTMLElement;
    private _childs: NodeListOf<HTMLElement>;

    constructor() {
        this._cont = app.ui.cont.querySelector('.intro')!;
        this._childs = this._cont.querySelectorAll(":scope> *");
    }

    show(delay?: number, complete?: Function) {
        this._cont.style.display = "block";
        anime.remove(this._childs);
        anime({
            targets: this._childs, opacity: [0, 1], translateY: ["4rem", 0],
            delay: anime.stagger(150, { start: delay }),
            duration: 600, easing: "easeOutQuad",
        });

        return 0;
    }

    hide(delay?: number, complete?: Function) {
        anime.remove(this._childs);
        anime({
            targets: this._childs, opacity: 0, translateY: 0,
            delay: anime.stagger(100, { start: delay }),
            duration: 500, easing: "easeOutQuad",
            complete: () => {
                this._cont.style.display = "none";
                complete && complete();
            }
        });

        return 350;
    }
}