import { app } from "..";
import { DataCarList } from "../data";

export default class UIPlatformMenu {
    private _cont: HTMLElement;
    private _itemCont: HTMLUListElement;
    private _slider: HTMLElement;

    constructor() {
        this._cont = document.querySelector('.intro .platform-menu')!;
        this._itemCont = this._cont.querySelector(".line .dots>ul")!;
        this._slider = this._cont.querySelector('.slider')!;
        this._slider.style.display = "none";

        let item: HTMLElement;
        for (let i = 0; i < this._itemCont.children.length; i++) {
            item = this._itemCont.children[i] as HTMLElement;
            item.style.left = (i / (this._itemCont.children.length - 1) * 100) + "%";
            item.querySelector('button')!.addEventListener('click', () => app.platform.panToCar(i));
        }
    }

    onPanning(perc: number) {
        this._slider.style.display = "";
        this._slider.style.left = (perc * 100) + "%";
    }
}