// import { Point } from "@pixi/math";
// import * as pixiUtils from '@pixi/utils';
import anime from "animejs";
import App from "./app";
// import PixiApp from "./pixiApp";

export const defWidth = 1920;
export const defHeight = 1080;
export const defRatioWH = defWidth / defHeight;
export const defRatioWidthVH = 177.78;
export const defRatioHeightVH = 100;
export const defMinHeight = 450;

export interface IPoint { x: number; y: number; };
export const appUtils = {
    deg2rad: (deg: number) => deg * Math.PI / 180,
    rad2deg: (rad: number) => rad * 180 / Math.PI,
    dist: (a: number, b: number) => Math.abs(a - b),
    distPoint: (a: { x: number, y: number }, b: { x: number, y: number }) => Math.hypot(a.x - b.x, a.y - b.y),
    lerp: (a: number, b: number, t: number) => (1 - t) * a + t * b,
    toss: (perc: number = .5) => Math.random() < perc,
    clamp: (n: number, min: number, max: number) => Math.min(Math.max(n, min), max),
    rand: (params: { min?: number, max?: number, toInt?: boolean }) => {
        params = params || { min: 0, max: 0 };
        params.min = params.min || 0;
        params.max = params.max || 0;
        const val = Math.random() * (params.max - params.min) + params.min;
        return params.toInt ? Math.floor(val) : val;
    },
    mouseTouchXY(e: MouseEvent | TouchEvent): IPoint {
        return (window.TouchEvent && e instanceof TouchEvent) ?
            { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY } :
            { x: (e as MouseEvent).clientX, y: (e as MouseEvent).clientY };
    },
    nullOrEmpty(val: any) { return val == null || val == undefined || val == ""; },
    // ...pixiUtils
};

export const YOUTUBE_IMG_LINK = "https://i3.ytimg.com/vi/";
export const STATIC_IMAGE_URL = "/assets/images/";

// export const app = new PixiApp();
export const app = new App();
globalThis.app = app;
globalThis.anime = anime;