import { IDataCar } from ".";

export default {
    id: "car_3008",
    urlPageId: "3008",
    title: "PEUGEOT SUV 3008",
    shortTitle: "3008",
    featuredPinFrameIndex: 1,
    colors: [
        { colorHex: "#027fb4", title: "Obsession Mavi", dir: "mavi_obs", featured: true },
        { colorHex: "#48596a", title: "İngaro Mavi", dir: "mavi_ingaro" },
        { colorHex: "#000000", title: "İnci Siyah", dir: "siyah" },
        { colorHex: "#E6E7E9", title: "Tekno Gri", dir: "gri_tekno" },
        { colorHex: "#292a2a", title: "Titan Gri", dir: "gri_titan" },
        { colorHex: "#F3F3F5", title: "Okenit Beyaz", dir: "beyaz_okenit" },
    ],
    infoLink: "https://www.peugeot.com.tr/peugeot-modelleri/3008.html",
    otherPackagesLink: "https://www.peugeot.com.tr/peugeot-modelleri/3008.html",
    broshureLink: "https://www.peugeot.com.tr/content/dam/peugeot/turkey/b2c/bro%C5%9F%C3%BCrler/2024/Eylul_Yeni_3008_TeknikBrosur.pdf",
    appointmentLink: 'yeni3008/suv',
    galleryPhotos: [
        { source: "gallery_0.jpg" }, { source: "gallery_1.jpg" }, { source: "gallery_2.jpg" }, { source: "gallery_3.jpg" },
        { source: "gallery_4.jpg" }, { source: "gallery_5.jpg" }, { source: "gallery_6.jpg" }, { source: "gallery_7.jpg" },
        { source: "gallery_8.jpg" }, { source: "gallery_9.jpg" }, { source: "gallery_10.jpg" }, { source: "gallery_11.jpg" },
        { source: "gallery_12.jpg" }, { source: "gallery_13.jpg" },
    ],
    videos: [
        { title: "Değişim Zamanı", type: "youtube", source: "XfHkm5sbHB8" },
    ],
    interiorImageNames: [
        "interior_0", "interior_1", "interior_2", "interior_3", "interior_4", "interior_5",
    ],
    pinsInterior: [
        {
            id: "sunroof-i", coords: { x: -88, y: 33 },
            speechKeywords: "cam tavan, iç tavan, tavan, sunroof",
        }, {
            id: "wheel", coords: { x: 57, y: -25 },
            speechKeywords: "direksiyon, kompakt direksiyon simidi",
        }, {
            id: "screen", coords: { x: 90, y: -23 },
            speechKeywords: "start stop buton, start, stop, buton, dokunmatik ekran, dokunmatik, kapasitif dokunmatik ekran, kapasitif, klima, radyo, ortam rengi, mirror screen, apple car play, apple car, bluetooth, wi-fi",
        }, {
            id: "midconsole", coords: { x: 97, y: -35 },
            speechKeywords: "orta konsol, konsol, şanzıman, quickshift, kablosuz şarj, şarj, vites",
        }, {
            id: "mid", coords: { x: 90, y: -75 },
            speechKeywords: "orta konsol, konsol, on kol dayama",
        }, {
            id: "gearbox", coords: { x: 82, y: -20 },
            speechKeywords: "orta konsol, konsol, on kol dayama",
        }, {
            id: "buttons", coords: { x: -89, y: -85 },
            speechKeywords: "ısıtmalı koltuk tuşları, ısıtma",
        }, {
            id: "seat", coords: { x: 40, y: -62 },
            speechKeywords: "masaj, düğme, masaj düğmeleri",
        }, {
            id: "backmid", coords: { x: -89, y: -13 },
            speechKeywords: "arka kol dayama",
        }, {
            id: "speakers", coords: { x: 49, y: -10 },
            speechKeywords: "hoparlör, focal, fokal, ses, ses sistemi",
        },
        {
            id: "ambiance", coords: { x: 100, y: -15 },
            speechKeywords: "hoparlör, focal, fokal, ses, ses sistemi",
        },
        {
            id: "panscreen", coords: { x: 70, y: -12 },
            speechKeywords: "hoparlör, focal, fokal, ses, ses sistemi",
        },
        {
            id: "visiopark", coords: { x: 83, y: -12 },
            speechKeywords: "hoparlör, focal, fokal, ses, ses sistemi",
        },
        {
            id: "cleancabin", coords: { x: 100, y: -21 },
            speechKeywords: "hoparlör, focal, fokal, ses, ses sistemi",
        },
    ],
    pinFrames: [
        { index: 0, pins: [{ id: "frontdesign", posXPerc: 59.38, posYPerc: 57.59 }, { id: "lights", posXPerc: 44.61, posYPerc: 54.94 }, { id: "rim", posXPerc: 37.28, posYPerc: 59.73 },] },
        { index: 1, pins: [{ id: "rim", posXPerc: 43.83, posYPerc: 59.42 }, { id: "lights", posXPerc: 52.95, posYPerc: 54.42 }, { id: "frontdesign", posXPerc: 67.61, posYPerc: 57.42 },] },
        { index: 2, pins: [{ id: "frontdesign", posXPerc: 74.06, posYPerc: 57.25 }, { id: "lights", posXPerc: 60.83, posYPerc: 54.09 }, { id: "rim", posXPerc: 49.95, posYPerc: 59.09 },] },
        { index: 3, pins: [{ id: "rim", posXPerc: 56.5, posYPerc: 60.75 }, { id: "lights", posXPerc: 67.93, posYPerc: 53.78 }, { id: "frontdesign", posXPerc: 79.5, posYPerc: 57.75 },] },
        { index: 4, pins: [{ id: "rim", posXPerc: 61.61, posYPerc: 61.08 }, { id: "lights", posXPerc: 76.16, posYPerc: 56.28 }, { id: "frontdesign", posXPerc: 85.61, posYPerc: 59.1 },] },
        { index: 5, pins: [{ id: "rim", posXPerc: 66.72, posYPerc: 60.77 }, { id: "lights", posXPerc: 76.17, posYPerc: 51.44 }, { id: "frontdesign", posXPerc: 88.72, posYPerc: 58.1 },] },
        { index: 6, pins: [{ id: "rim", posXPerc: 69.17, posYPerc: 61.58 }, { id: "lights", posXPerc: 81.17, posYPerc: 51.44 },] },
        { index: 7, pins: [{ id: "lights", posXPerc: 84.61, posYPerc: 51.6 }, { id: "rim", posXPerc: 72.39, posYPerc: 62.75 },] },
        { index: 8, pins: [{ id: "rim", posXPerc: 77.06, posYPerc: 63.1 }, { id: "lights", posXPerc: 85.17, posYPerc: 51.95 },] },
        { index: 9, pins: [{ id: "rim", posXPerc: 78.5, posYPerc: 61.59 },] },
        { index: 10, pins: [{ id: "rim", posXPerc: 80.95, posYPerc: 59.94 },] },
        { index: 11, pins: [{ id: "rim", posXPerc: 80.39, posYPerc: 59.11 },] },
        { index: 12, pins: [{ id: "trunk", posXPerc: 33.61, posYPerc: 42.44 }, { id: "spoiler", posXPerc: 43.27, posYPerc: 37.44 }, { id: "backdesign", posXPerc: 27.72, posYPerc: 53.42 }, { id: "rim", posXPerc: 76.61, posYPerc: 59.9 },] },
        { index: 13, pins: [{ id: "backdesign", posXPerc: 33.05, posYPerc: 54.09 }, { id: "spoiler", posXPerc: 49.39, posYPerc: 37.78 }, { id: "trunk", posXPerc: 35.73, posYPerc: 41.61 }, { id: "rim", posXPerc: 75.17, posYPerc: 58.9 },] },
        { index: 14, pins: [{ id: "backdesign", posXPerc: 39.72, posYPerc: 53.26 }, { id: "spoiler", posXPerc: 54.72, posYPerc: 37.94 }, { id: "trunk", posXPerc: 40.5, posYPerc: 41.78 }, { id: "rim", posXPerc: 62.5, posYPerc: 60.09 },] },
        { index: 15, pins: [{ id: "spoiler", posXPerc: 58.61, posYPerc: 38.11 }, { id: "trunk", posXPerc: 42.39, posYPerc: 42.11 }, { id: "backdesign", posXPerc: 45.72, posYPerc: 54.09 }, { id: "rim", posXPerc: 66.06, posYPerc: 59.91 },] },
        { index: 16, pins: [{ id: "spoiler", posXPerc: 59.84, posYPerc: 38.28 }, { id: "trunk", posXPerc: 42.83, posYPerc: 42.44 }, { id: "backdesign", posXPerc: 50.17, posYPerc: 54.42 },] },
        { index: 17, pins: [{ id: "spoiler", posXPerc: 61.5, posYPerc: 38.61 }, { id: "trunk", posXPerc: 46.39, posYPerc: 41.61 }, { id: "backdesign", posXPerc: 54.94, posYPerc: 58.24 },] },
        { index: 18, pins: [{ id: "spoiler", posXPerc: 45.16, posYPerc: 38.28 }, { id: "backdesign", posXPerc: 60.06, posYPerc: 51.76 }, { id: "trunk", posXPerc: 57.95, posYPerc: 41.45 },] },
        { index: 19, pins: [{ id: "spoiler", posXPerc: 50.83, posYPerc: 37.78 }, { id: "trunk", posXPerc: 61.06, posYPerc: 40.95 }, { id: "backdesign", posXPerc: 66.83, posYPerc: 54.59 }, { id: "rim", posXPerc: 43.5, posYPerc: 60.4 },] },
        { index: 20, pins: [{ id: "backdesign", posXPerc: 72.72, posYPerc: 52.26 }, { id: "spoiler", posXPerc: 56.61, posYPerc: 37.45 }, { id: "trunk", posXPerc: 67.16, posYPerc: 41.61 }, { id: "rim", posXPerc: 49.28, posYPerc: 59.42 },] },
        { index: 21, pins: [{ id: "spoiler", posXPerc: 62.27, posYPerc: 36.95 }, { id: "trunk", posXPerc: 69.83, posYPerc: 40.94 }, { id: "backdesign", posXPerc: 77.83, posYPerc: 54.09 }, { id: "rim", posXPerc: 21.84, posYPerc: 59.93 },] },
        { index: 22, pins: [{ id: "rim", posXPerc: 23.5, posYPerc: 59.09 },] },
        { index: 23, pins: [{ id: "rim", posXPerc: 23.95, posYPerc: 59.58 },] },
        { index: 24, pins: [{ id: "rim", posXPerc: 23.94, posYPerc: 61.42 },] },
        { index: 25, pins: [{ id: "rim", posXPerc: 23.84, posYPerc: 62.76 }, { id: "lights", posXPerc: 17.06, posYPerc: 52.6 },] },
        { index: 26, pins: [{ id: "rim", posXPerc: 28.05, posYPerc: 63.59 }, { id: "lights", posXPerc: 14.61, posYPerc: 57.77 },] },
        { index: 27, pins: [{ id: "rim", posXPerc: 34.05, posYPerc: 62.93 }, { id: "frontdesign", posXPerc: 11.16, posYPerc: 59.6 }, { id: "lights", posXPerc: 19.5, posYPerc: 56.78 },] },
        { index: 28, pins: [{ id: "rim", posXPerc: 40.5, posYPerc: 60.58 }, { id: "lights", posXPerc: 25.06, posYPerc: 56.6 }, { id: "frontdesign", posXPerc: 15.72, posYPerc: 59.42 },] },
        { index: 29, pins: [{ id: "rim", posXPerc: 46.72, posYPerc: 62.76 }, { id: "frontdesign", posXPerc: 20.94, posYPerc: 59.28 }, { id: "lights", posXPerc: 33.28, posYPerc: 56.11 },] },
        { index: 30, pins: [{ id: "rim", posXPerc: 53.28, posYPerc: 62.94 }, { id: "frontdesign", posXPerc: 26.83, posYPerc: 59.12 }, { id: "lights", posXPerc: 41.16, posYPerc: 54.76 },] },
        { index: 31, pins: [{ id: "rim", posXPerc: 58.84, posYPerc: 61.77 }, { id: "lights", posXPerc: 49.84, posYPerc: 56.1 }, { id: "frontdesign", posXPerc: 33.83, posYPerc: 59.27 },] },
        { index: 32, pins: [{ id: "rim", posXPerc: 65.28, posYPerc: 61.93 }, { id: "lights", posXPerc: 58.28, posYPerc: 56.6 }, { id: "frontdesign", posXPerc: 41.72, posYPerc: 59.6 },] },
        { index: 33, pins: [{ id: "rim", posXPerc: 70.95, posYPerc: 61.76 }, { id: "lights", posXPerc: 61.17, posYPerc: 56.78 }, { id: "frontdesign", posXPerc: 40.95, posYPerc: 60.26 },] },
        { index: 34, pins: [{ id: "lights", posXPerc: 65.83, posYPerc: 56.77 }, { id: "frontdesign", posXPerc: 45.93, posYPerc: 60.59 },] },
        { index: 35, pins: [{ id: "rim", posXPerc: 32.61, posYPerc: 59.43 }, { id: "lights", posXPerc: 69.28, posYPerc: 55.96 }, { id: "frontdesign", posXPerc: 52.05, posYPerc: 60.76 },] },
    ]
} as IDataCar;