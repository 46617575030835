import { IDataCar } from ".";

export default {
    id: "car_rifter",
    urlPageId: "rifter",
    title: "PEUGEOT Rifter",
    shortTitle: "Rifter",
    colors: [
        { colorHex: "#4c4c2d", title: "Haki Yeşil", dir: "yesil", featured: true },
        { colorHex: "#000000", title: "İnci Siyah", dir: "siyah" },
        { colorHex: "#e1e2e4", title: "Tekno Gri", dir: "tekno", },
        { colorHex: "#0e416c", title: "Turkuaz Mavi", dir: "mavi", },
        { colorHex: "#f6f7f8", title: "Buz Beyaz", dir: "beyaz", },
    ],
    infoLink: "https://www.peugeot.com.tr/peugeot-modelleri/rifter.html",
    otherPackagesLink: "https://www.peugeot.com.tr/peugeot-modelleri/rifter.html",
    broshureLink: "https://www.peugeot.com.tr/content/dam/peugeot/turkey/b2c/bro%C5%9F%C3%BCrler/2024/haziran_044_Rifter_TeknikBrosuru.pdf",
    appointmentLink: 'rifter',
    galleryPhotos: [
        { source: "gallery_0.jpg" }, { source: "gallery_1.jpg" }, { source: "gallery_2.jpg" }, { source: "gallery_3.jpg" },
        { source: "gallery_4.jpg" }, { source: "gallery_5.jpg" }, { source: "gallery_6.jpg" }, { source: "gallery_7.jpg" },
    ],
    videos: [
        { title: "Hayatı Rifter Yaşayanlar", type: "youtube", source: "abQxA10xq3g" },
        { title: "Ogeday Girişken", type: "youtube", source: "Mi44JIzmsyo" },
        { title: "Sarp Can Köroğlu", type: "youtube", source: "XMlBizSfJBU" },
        { title: "Barış Telli", type: "youtube", source: "-ck_UIsDDBI" },
    ],
    interiorImageNames: [
        "interior_0", "interior_1", "interior_2", "interior_3", "interior_4", "interior_5",
    ],
    pinsInterior: [
        {
            id: "screen", coords: { x: 87, y: -13 },
            speechKeywords: "ön gösterge paneli, kontrol paneli",
        }, {
            id: "park", coords: { x: 92, y: -10 },
            speechKeywords: "multimedya, ekran, mirror screen, radyo, klima, navigasyon, park, sensör, geri dönüş kamerası",
        },{
            id: "digital", coords: { x: 68, y: -8 },
            speechKeywords: "multimedya, ekran, mirror screen, radyo, klima, navigasyon, park, sensör, geri dönüş kamerası",
        },  {
            id: "ventilation", coords: { x: 96, y: -78 },
            speechKeywords: "havalandırma, ızgara, hız ayarı",
        }, {
            id: "windows", coords: { x: 50, y: -20 },
            speechKeywords: "cam, pencere, elekttrikli cam, karartılmış",
        }, {
            id: "wheel", coords: { x: 61.75, y: -20.75 },
            speechKeywords: "direksiyon",
        }, {
            id: "midconsole", coords: { x: 90, y: -49 },
            speechKeywords: "advanced grip control, çekiş kontrol, yokuş iniş destek sistemi",
        }, {
            id: "frontseat", coords: { x: 106, y: -50 },
            speechKeywords: "quente, ambiyans, casual, kumaş, koltuk",
        }, {
            id: "backseat", coords: { x: 264, y: -55 },
            speechKeywords: "arka koltuk",
        }, {
            id: "backseatout", coords: { x: 265, y: -23 },
            speechKeywords: "arka koltuk",
        }, {
            id: "lugage", coords: { x: 262, y: 15 },
            speechKeywords: "bagaj üzeri, bagaj",
        }, {
            id: "sunroof", coords: { x: -24, y: 45 },
            speechKeywords: "zenith cam tavan, tavan, panoramik, tavan perdesi",
        }, {
            id: "sunroof", coords: { x: 88, y: 15 },
            speechKeywords: "zenith cam tavan, tavan, panoramik, tavan perdesi",
        },
    ],
    pinFrames: [
        { index: 0, pins: [{ id: "lights", posXPerc: 33.17, posYPerc: 52.25 }, { id: "frontdesign", posXPerc: 43.28, posYPerc: 56.24 }, { id: "body", posXPerc: 49.72, posYPerc: 79.87 },] },
        { index: 1, pins: [{ id: "lights", posXPerc: 33.61, posYPerc: 52.25 }, { id: "frontdesign", posXPerc: 47.17, posYPerc: 56.24 }, { id: "body", posXPerc: 57.61, posYPerc: 79.03 },] },
        { index: 2, pins: [{ id: "lights", posXPerc: 41.39, posYPerc: 51.41 }, { id: "frontdesign", posXPerc: 56.5, posYPerc: 56.74 }, { id: "body", posXPerc: 64.39, posYPerc: 78.04 },] },
        { index: 3, pins: [{ id: "lights", posXPerc: 49.94, posYPerc: 50.58 }, { id: "frontdesign", posXPerc: 66.72, posYPerc: 57.24 }, { id: "body", posXPerc: 68.94, posYPerc: 78.04 },] },
        { index: 4, pins: [{ id: "frontdesign", posXPerc: 73.39, posYPerc: 56.57 }, { id: "rim", posXPerc: 50.83, posYPerc: 65.39 }, { id: "lights", posXPerc: 57.94, posYPerc: 51.25 }, { id: "mirror", posXPerc: 38.72, posYPerc: 44.43 }, { id: "topdesign", posXPerc: 38.5, posYPerc: 27.47 }, { id: "door", posXPerc: 27.83, posYPerc: 48.59 }, { id: "body", posXPerc: 29.94, posYPerc: 72.05 },] },
        { index: 5, pins: [{ id: "frontdesign", posXPerc: 80.17, posYPerc: 56.74 }, { id: "rim", posXPerc: 56.28, posYPerc: 65.39 }, { id: "lights", posXPerc: 65.83, posYPerc: 51.41 }, { id: "topdesign", posXPerc: 40.94, posYPerc: 26.46 }, { id: "mirror", posXPerc: 42.39, posYPerc: 44.43 }, { id: "door", posXPerc: 28.72, posYPerc: 49.08 }, { id: "body", posXPerc: 32.83, posYPerc: 71.88 },] },
        { index: 6, pins: [{ id: "rim", posXPerc: 64.28, posYPerc: 65.39 }, { id: "lights", posXPerc: 72.06, posYPerc: 52.08 }, { id: "mirror", posXPerc: 47.83, posYPerc: 42.76 }, { id: "topdesign", posXPerc: 42.05, posYPerc: 27.46 }, { id: "door", posXPerc: 31.83, posYPerc: 50.58 }, { id: "frontdesign", posXPerc: 84.83, posYPerc: 56.74 }, { id: "body", posXPerc: 36.06, posYPerc: 72.38 },] },
        { index: 7, pins: [{ id: "topdesign", posXPerc: 45.39, posYPerc: 27.13 }, { id: "mirror", posXPerc: 52.06, posYPerc: 42.6 }, { id: "rim", posXPerc: 67.28, posYPerc: 65.06 }, { id: "door", posXPerc: 33.94, posYPerc: 49.25 }, { id: "body", posXPerc: 38.83, posYPerc: 72.71 },] },
        { index: 8, pins: [{ id: "rim", posXPerc: 76.06, posYPerc: 66.39 }, { id: "mirror", posXPerc: 58.39, posYPerc: 44.93 }, { id: "topdesign", posXPerc: 48.95, posYPerc: 27.8 }, { id: "door", posXPerc: 36.83, posYPerc: 50.08 }, { id: "body", posXPerc: 42.83, posYPerc: 73.54 },] },
        { index: 9, pins: [{ id: "rim", posXPerc: 74.28, posYPerc: 67.74 }, { id: "mirror", posXPerc: 62.28, posYPerc: 44.93 }, { id: "topdesign", posXPerc: 49.83, posYPerc: 28.63 }, { id: "door", posXPerc: 40.06, posYPerc: 51.08 }, { id: "body", posXPerc: 44.72, posYPerc: 75.21 },] },
        { index: 10, pins: [{ id: "lights", posXPerc: 85.61, posYPerc: 54.24 }, { id: "rim", posXPerc: 77.39, posYPerc: 66.56 }, { id: "mirror", posXPerc: 65.28, posYPerc: 44.59 }, { id: "topdesign", posXPerc: 51.84, posYPerc: 29.29 }, { id: "door", posXPerc: 43.06, posYPerc: 52.08 }, { id: "body", posXPerc: 49.17, posYPerc: 75.37 },] },
        { index: 11, pins: [{ id: "rim", posXPerc: 79.28, posYPerc: 67.05 }, { id: "mirror", posXPerc: 68.5, posYPerc: 46.59 }, { id: "topdesign", posXPerc: 54.28, posYPerc: 30.46 }, { id: "door", posXPerc: 48.72, posYPerc: 52.41 }, { id: "body", posXPerc: 52.83, posYPerc: 74.54 },] },
        { index: 12, pins: [{ id: "rim", posXPerc: 35.06, posYPerc: 66.56 }, { id: "mirror", posXPerc: 71.61, posYPerc: 45.59 }, { id: "topdesign", posXPerc: 56.5, posYPerc: 30.96 }, { id: "door", posXPerc: 54.94, posYPerc: 52.91 }, { id: "body", posXPerc: 57.61, posYPerc: 73.88 },] },
        { index: 13, pins: [{ id: "mirror", posXPerc: 73.17, posYPerc: 46.92 }, { id: "rim", posXPerc: 41.17, posYPerc: 67.39 }, { id: "topdesign", posXPerc: 59.05, posYPerc: 31.63 }, { id: "door", posXPerc: 58.17, posYPerc: 51.91 }, { id: "body", posXPerc: 61.28, posYPerc: 72.71 },] },
        { index: 14, pins: [{ id: "rim", posXPerc: 49.61, posYPerc: 68.22 }, { id: "mirror", posXPerc: 73.28, posYPerc: 46.42 }, { id: "topdesign", posXPerc: 59.83, posYPerc: 31.12 }, { id: "door", posXPerc: 61.94, posYPerc: 52.58 }, { id: "body", posXPerc: 64.06, posYPerc: 72.23 },] },
        { index: 15, pins: [{ id: "mirror", posXPerc: 74.39, posYPerc: 47.09 }, { id: "rim", posXPerc: 55.72, posYPerc: 68.72 }, { id: "topdesign", posXPerc: 60.72, posYPerc: 31.46 }, { id: "door", posXPerc: 64.72, posYPerc: 53.41 }, { id: "body", posXPerc: 67.5, posYPerc: 73.23 },] },
        { index: 16, pins: [{ id: "rim", posXPerc: 61.28, posYPerc: 68.89 }, { id: "mirror", posXPerc: 73.5, posYPerc: 46.59 }, { id: "topdesign", posXPerc: 62.06, posYPerc: 31.63 }, { id: "door", posXPerc: 66.17, posYPerc: 53.08 }, { id: "trunk", posXPerc: 38.94, posYPerc: 36.28 }, { id: "body", posXPerc: 36.94, posYPerc: 73.38 },] },
        { index: 17, pins: [{ id: "mirror", posXPerc: 71.5, posYPerc: 47.25 }, { id: "topdesign", posXPerc: 60.5, posYPerc: 27.62 }, { id: "trunk", posXPerc: 40.84, posYPerc: 36.29 }, { id: "backlights", posXPerc: 30.28, posYPerc: 44.43 }, { id: "door", posXPerc: 67.72, posYPerc: 54.74 }, { id: "body", posXPerc: 41.94, posYPerc: 74.21 },] },
        { index: 18, pins: [{ id: "backlights", posXPerc: 32.72, posYPerc: 43.76 }, { id: "trunk", posXPerc: 53.84, posYPerc: 37.97 }, { id: "body", posXPerc: 50.5, posYPerc: 74.04 },] },
        { index: 19, pins: [{ id: "trunk", posXPerc: 61.94, posYPerc: 37.45 }, { id: "backlights", posXPerc: 37.5, posYPerc: 43.11 }, { id: "rim", posXPerc: 33.28, posYPerc: 69.38 }, { id: "mirror", posXPerc: 28.72, posYPerc: 47.59 }, { id: "topdesign", posXPerc: 38.94, posYPerc: 29.45 }, { id: "body", posXPerc: 54.94, posYPerc: 74.71 },] },
        { index: 20, pins: [{ id: "rim", posXPerc: 37.5, posYPerc: 66.72 }, { id: "trunk", posXPerc: 68.61, posYPerc: 42.61 }, { id: "door", posXPerc: 31.28, posYPerc: 53.91 }, { id: "mirror", posXPerc: 26.39, posYPerc: 46.92 }, { id: "body", posXPerc: 61.61, posYPerc: 73.54 },] },
        { index: 21, pins: [{ id: "rim", posXPerc: 44.61, posYPerc: 67.72 }, { id: "trunk", posXPerc: 70.83, posYPerc: 37.78 }, { id: "topdesign", posXPerc: 45.39, posYPerc: 29.62 }, { id: "mirror", posXPerc: 25.83, posYPerc: 47.92 }, { id: "door", posXPerc: 35.28, posYPerc: 53.08 }, { id: "body", posXPerc: 69.17, posYPerc: 73.21 },] },
        { index: 22, pins: [{ id: "rim", posXPerc: 52.17, posYPerc: 68.22 }, { id: "mirror", posXPerc: 25.83, posYPerc: 47.59 }, { id: "topdesign", posXPerc: 48.72, posYPerc: 29.46 }, { id: "door", posXPerc: 37.39, posYPerc: 53.91 },] },
        { index: 23, pins: [{ id: "rim", posXPerc: 58.72, posYPerc: 68.72 }, { id: "lights", posXPerc: 16.72, posYPerc: 54.91 }, { id: "mirror", posXPerc: 26.5, posYPerc: 46.92 }, { id: "topdesign", posXPerc: 51.39, posYPerc: 29.3 }, { id: "door", posXPerc: 41.17, posYPerc: 53.41 }, { id: "body", posXPerc: 38.06, posYPerc: 74.88 },] },
        { index: 24, pins: [{ id: "rim", posXPerc: 21.06, posYPerc: 66.39 }, { id: "lights", posXPerc: 15.72, posYPerc: 54.24 }, { id: "mirror", posXPerc: 28.39, posYPerc: 45.92 }, { id: "topdesign", posXPerc: 55.5, posYPerc: 28.79 }, { id: "door", posXPerc: 45.5, posYPerc: 53.58 }, { id: "body", posXPerc: 41.39, posYPerc: 74.21 },] },
        { index: 25, pins: [{ id: "rim", posXPerc: 72.17, posYPerc: 67.22 }, { id: "mirror", posXPerc: 30.94, posYPerc: 45.92 }, { id: "topdesign", posXPerc: 57.28, posYPerc: 29.12 }, { id: "door", posXPerc: 51.06, posYPerc: 51.75 }, { id: "body", posXPerc: 46.28, posYPerc: 73.71 },] },
        { index: 26, pins: [{ id: "rim", posXPerc: 23.06, posYPerc: 68.39 }, { id: "mirror", posXPerc: 33.61, posYPerc: 44.59 }, { id: "topdesign", posXPerc: 59.83, posYPerc: 27.96 }, { id: "door", posXPerc: 54.83, posYPerc: 52.91 }, { id: "body", posXPerc: 49.72, posYPerc: 75.21 },] },
        { index: 27, pins: [{ id: "rim", posXPerc: 24.5, posYPerc: 69.05 }, { id: "mirror", posXPerc: 36.28, posYPerc: 44.76 }, { id: "topdesign", posXPerc: 62.94, posYPerc: 28.29 }, { id: "door", posXPerc: 58.61, posYPerc: 52.58 }, { id: "body", posXPerc: 53.06, posYPerc: 75.21 },] },
        { index: 28, pins: [{ id: "rim", posXPerc: 26.94, posYPerc: 69.22 }, { id: "mirror", posXPerc: 40.94, posYPerc: 44.43 }, { id: "topdesign", posXPerc: 64.17, posYPerc: 27.8 }, { id: "door", posXPerc: 61.72, posYPerc: 51.25 }, { id: "body", posXPerc: 56.72, posYPerc: 74.04 },] },
        { index: 29, pins: [{ id: "rim", posXPerc: 30.83, posYPerc: 66.72 }, { id: "mirror", posXPerc: 45.39, posYPerc: 43.26 }, { id: "topdesign", posXPerc: 65.84, posYPerc: 27.46 }, { id: "door", posXPerc: 64.83, posYPerc: 50.92 }, { id: "body", posXPerc: 59.94, posYPerc: 72.88 },] },
        { index: 30, pins: [{ id: "rim", posXPerc: 37.61, posYPerc: 66.89 }, { id: "lights", posXPerc: 26.39, posYPerc: 51.91 }, { id: "mirror", posXPerc: 50.83, posYPerc: 42.93 }, { id: "topdesign", posXPerc: 66.28, posYPerc: 27.46 }, { id: "frontdesign", posXPerc: 12.61, posYPerc: 58.24 }, { id: "door", posXPerc: 67.94, posYPerc: 50.58 }, { id: "body", posXPerc: 63.83, posYPerc: 72.21 },] },
        { index: 31, pins: [{ id: "rim", posXPerc: 47.17, posYPerc: 67.05 }, { id: "lights", posXPerc: 36.72, posYPerc: 51.58 }, { id: "mirror", posXPerc: 59.39, posYPerc: 41.93 }, { id: "topdesign", posXPerc: 69.94, posYPerc: 27.63 }, { id: "frontdesign", posXPerc: 20.72, posYPerc: 58.24 }, { id: "door", posXPerc: 72.06, posYPerc: 51.25 }, { id: "body", posXPerc: 69.06, posYPerc: 69.05 },] },
        { index: 32, pins: [{ id: "rim", posXPerc: 50.94, posYPerc: 67.39 }, { id: "lights", posXPerc: 41.5, posYPerc: 51.91 }, { id: "mirror", posXPerc: 61.83, posYPerc: 42.43 }, { id: "topdesign", posXPerc: 69.83, posYPerc: 27.46 }, { id: "frontdesign", posXPerc: 24.39, posYPerc: 56.57 }, { id: "door", posXPerc: 72.17, posYPerc: 50.75 }, { id: "body", posXPerc: 69.39, posYPerc: 71.55 },] },
        { index: 33, pins: [{ id: "lights", posXPerc: 49.5, posYPerc: 51.25 }, { id: "frontdesign", posXPerc: 31.17, posYPerc: 57.24 },] },
        { index: 34, pins: [{ id: "lights", posXPerc: 24.39, posYPerc: 51.25 }, { id: "frontdesign", posXPerc: 42.17, posYPerc: 57.9 }, { id: "body", posXPerc: 35.5, posYPerc: 78.04 },] },
        { index: 35, pins: [{ id: "frontdesign", posXPerc: 35.72, posYPerc: 56.92 }, { id: "body", posXPerc: 43.5, posYPerc: 78.37 },] },
    ]
} as IDataCar;