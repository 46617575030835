import anime, { easings } from 'animejs';
import { EventEmitter } from 'eventemitter3';
import { app, appUtils, defRatioHeightVH, defRatioWidthVH } from '.';
import { DataCarList, DEFAULT_CAR_INDEX } from './data';
import Panner, { PannerEvents } from './entities/panner';
import UIPlatformMenu from './entities/uiPlatformMenu';

export enum PlatformEvents {
    None = "none",
}

const _ZOOM_SCALE = 1.75;

export class Platform extends EventEmitter<PlatformEvents> {
    private _panner: Panner;
    private _cont: HTMLElement;
    private _stands: HTMLElement;
    private _walls: HTMLElement;
    private _lights: HTMLElement;
    private _carElmList: NodeListOf<HTMLElement>;
    private _zoomedCarIndex: number = -1;
    private _animating: boolean;
    private _menu: UIPlatformMenu;

    get cont() { return this._cont; }
    get stands() { return this._stands; }
    get panner() { return this._panner; }
    get zoomedCarIndex() { return this._zoomedCarIndex; }
    get zoomed() { return this._zoomedCarIndex != -1; }

    constructor() {
        super();
    }

    init() {
        this._cont = document.getElementById('platform-container')!;
        this._walls = this._cont.querySelector(".walls")!;
        this._stands = this._cont.querySelector(".stands")!;
        this._lights = this._cont.querySelector(".lights")!;

        (this._carElmList = this._stands.querySelectorAll(".main_stand .car")).forEach((item, index) => {
            if (item.classList.contains('button')) item.addEventListener("click", () => this.zoomInTo(index));
        });

        (this._panner = new Panner(this._cont, this._stands))
            .on(PannerEvents.Panning, () => this._onPanning());
        this._panner.enabled = false;

        this._menu = new UIPlatformMenu();
    }

    playStartAnim() {
        this._animating = true;
        const startDelay = 600;

        anime({
            targets: this._walls,
            opacity: [0, 1],
            duration: 600, delay: startDelay + 100, easing: "linear"
        });

        [this._walls.querySelectorAll(":scope>li:first-child>span"),
        this._walls.querySelectorAll(":scope>li:last-child>span")].forEach((item, index, arr) => {
            anime({
                targets: item,
                keyframes: [
                    { opacity: [0, .75], duration: 200 }, { opacity: .3, duration: 100 },
                    { opacity: 1, duration: 200, easing: "linear" }
                ], easing: "easeInOutQuad",
                delay: anime.stagger(150, { start: startDelay + 800 })
            });
        });

        anime({
            targets: this._lights.querySelectorAll("li"),
            keyframes: [
                { opacity: [0, .5], duration: 100 },
                { opacity: 1, duration: 250 }, { opacity: .5, duration: 75 }, { opacity: 1, duration: 100 },
                { opacity: .35, duration: 75, delay: 25 }, { opacity: 1, duration: 250, easing: "linear" }
            ],
            delay: anime.stagger(150, { start: startDelay, from: 'center' })
        });

        anime({
            targets: this._stands.querySelectorAll("li"),
            opacity: [0, 1], duration: 600, easing: "linear",
            delay: anime.stagger(400, { start: startDelay + 300, from: "center" })
        });

        anime({
            targets: this._stands.querySelectorAll("li>span"),
            keyframes: [
                { opacity: [0, .75], duration: 150 }, { opacity: .4, duration: 100 },
                { opacity: 1, duration: 250, easing: "linear" }
            ],
            delay: anime.stagger(300, { start: startDelay + 500, from: "center" })
        });

        anime({
            targets: this._stands.querySelectorAll("li div>span.stand"),
            keyframes: [
                { opacity: [0, .8], duration: 100 }, { opacity: .5, duration: 50 },
                { opacity: 1, duration: 300, easing: "linear" }
            ],
            delay: anime.stagger(400, { start: startDelay + 600, from: "center" })
        });

        const urlPageCarIndex = DataCarList.findIndex(v => v.urlPageId == app.urlParams.carPage);
        const pannerX = { val: (urlPageCarIndex > -1 ? urlPageCarIndex : DEFAULT_CAR_INDEX) / (DataCarList.length - 1) };
        this._panner.panTo(pannerX.val, true);

        anime({
            targets: this._stands.querySelectorAll("li div>span.car>canvas"),
            opacity: [0, 1], translateX: ["-2%", 0], translateY: ["-1%", 0], easing: "easeInOutSine", duration: 400,
            delay: anime.stagger(300, { start: startDelay + 800, from: "center" }),
            complete: () => {
                this._panner.enabled = true;
                this._panner.inputEnabled = false;

                if (urlPageCarIndex > -1) {
                    this._panner.inputEnabled = true;
                    this._animating = false;
                    this.zoomInTo(urlPageCarIndex);
                } else {

                    // TODO release comment
                    /* this._panner.inputEnabled = true;
                    this._animating = false;
                    return; */

                    anime({
                        targets: pannerX,
                        val: [
                            { value: Math.max(0, pannerX.val - .25), duration: 600 },
                            { value: pannerX.val, duration: 600, delay: 100 },
                            { value: Math.min(1, pannerX.val + .25), duration: 400, delay: 100 },
                            { value: pannerX.val, duration: 400, delay: 100 },
                        ],
                        easing: "linear",
                        update: () => this._panner.panTo(pannerX.val),
                        complete: () => {
                            this._panner.inputEnabled = true;
                            this._animating = false;
                        }
                    });
                }
            }
        });
    }

    panToCar(carIndex: number) {
        if (this._animating || this.zoomed)
            return;

        this._panner.panTo(carIndex / (DataCarList.length - 1));
    }

    zoomInTo(carIndex: number) {
        if (this._panner.isPanning || this._animating || this.zoomed)
            return;

        this._animating = true;
        this._zoomedCarIndex = carIndex;

        this._panner.panTo(this._zoomedCarIndex / (DataCarList.length - 1), false, () => {
            this._panner.enabled = false;
            const delay = app.ui.changeCarSec(carIndex, true);

            const wPerc = (this._stands.offsetWidth / this._cont.offsetWidth) * 100,
                hPerc = (this._stands.offsetHeight / this._cont.offsetHeight) * 100,
                nWPerc = wPerc * _ZOOM_SCALE,
                nHPerc = hPerc * _ZOOM_SCALE;

            const car = this._carElmList[carIndex];
            car.classList.remove("button");

            const carParent = car.closest('li')!,
                translateToCarX = (carParent.offsetLeft + carParent.offsetWidth * .5) * -_ZOOM_SCALE / this._cont.offsetWidth * 100 + 50;

            app.ui.car?.exterior.load(() => anime({
                targets: this._stands,
                width: [wPerc + "%", nWPerc + '%'], height: [hPerc + "%", nHPerc + '%'],
                left: translateToCarX + "%", top: ["57%", "50%"],
                delay: delay + 150, duration: 750, easing: "easeInOutQuad",
                complete: () => {
                    this._animating = false;
                }
            }));
        });
    }

    zoomOut(complete?: Function) {
        if (this._animating || !this.zoomed)
            return;

        this._animating = true;
        this._carElmList[this._zoomedCarIndex].classList.add("button");
        this._zoomedCarIndex = -1;
        const delay = app.ui.changeCarSec(-1);

        const nw = (this._stands.offsetWidth / this._cont.offsetWidth) * 100 / _ZOOM_SCALE,
            nh = (this._stands.offsetHeight / this._cont.offsetHeight) * 100 / _ZOOM_SCALE;

        anime({
            targets: this._stands,
            width: nw + '%', height: nh + '%',
            left: this._getPannerXForStands().toFixed(2) + "%", top: "57%",
            delay, duration: 750, easing: "easeInOutQuad",
            complete: () => {
                this._panner.inputEnabled = true;
                this._panner.enabled = true;
                this._panner.panTo(this._panner.percent.x, true);
                this._animating = false;
                complete && complete();
            }
        });
    }

    private _tOutAmbienceLight: NodeJS.Timeout;
    private _ambienceColorIndex: number = 0;
    
    cycleAmbienceLights(stop: boolean = false) {
        if (!app.ui.car)
            stop = true;

        if (stop || !app.ui.car!.data.ambienceColors || this._ambienceColorIndex >= app.ui.car!.data.ambienceColors.length)
            this._ambienceColorIndex = 0;

        const color = stop ? "" : app.ui.car!.data.ambienceColors![this._ambienceColorIndex++];
        this._walls.querySelectorAll(":scope>li>span").forEach(item => {
            (item as HTMLElement).style.backgroundColor = color;
        });

        clearTimeout(this._tOutAmbienceLight);
        if (!stop) this._tOutAmbienceLight = setTimeout(() => this.cycleAmbienceLights(), 4000);
    }

    private _getPannerXForStands() {
        // TODO adjust after new car added
        const rightSpace = [, , , , 27.5, 26.55, 25.15, 23.4,][DataCarList.length - 1]!;
        return this._panner.percent.x * DataCarList.length * -(rightSpace + DataCarList.length * 2.7) + 24.5;
    }

    private _onPanning() {
        this._stands.style.left = this._getPannerXForStands().toFixed(2) + "%";


        this._menu.onPanning(this._panner.percent.x);
    }
}