import anime from "animejs";
import { app } from "..";
import { IDataCar } from "../data";
import { IUISec } from "../ui";
import CarExterior from "./carExterior";
import CarInterior from "./carInterior";
import { UIViewType } from "./uiViews";

export default class UISecCar implements IUISec {
    private _cont: HTMLElement;
    private _descChilds: NodeListOf<HTMLElement>;
    private _exterior: CarExterior;
    private _interior: CarInterior;
    private _data: IDataCar;
    private _360ico: SVGElement;
    private _btnInterior: HTMLButtonElement;
    private _btnContact: HTMLButtonElement;
    private _title: HTMLImageElement;
    private _descShowHide: HTMLButtonElement;
    private _desc: HTMLElement;
    private _index: number;
    private _btnDivs: HTMLDivElement[];

    get index() { return this._index; }
    get data() { return this._data; }
    get exterior() { return this._exterior; }
    get interior() { return this._interior; }
    get color() { return this._data.colors[this.exterior!.colorIndex]; }

    constructor(index: number, data?: IDataCar) {
        if (!data)
            return;

        this._index = index;
        this._data = data;
        this._cont = app.ui.cont.querySelector('.' + data.id)!;

        this._exterior = new CarExterior(data, this._cont.querySelector(".pin-cont")!);
        this._interior = new CarInterior(data, this._cont.querySelector(".pin-cont-interior")!);

        this._desc = this._cont.querySelector('.desc')!;
        this._descChilds = this._desc.querySelectorAll('h1, h2, li, :scope>button');
        this._title = this._cont.querySelector("img.title")!;
        this._360ico = this._cont.querySelector("img.ico360")!;

        (this._descShowHide = this._cont.querySelector(".desc button.desc-show-hide")!).addEventListener("click",
            () => {
                if (!this._desc.classList.contains("hide"))
                    this._desc.classList.add('hide');
                else
                    this._desc.classList.remove('hide');
            });

        (this._btnInterior = this._cont.querySelector("button.interior")!).addEventListener("click",
            () => this._interior.show());

        (this._btnContact = this._cont.querySelector("button.contact")!).addEventListener("click",
            () => app.ui.carSubMenu.onBtnClick(this._btnContact));

        this._btnDivs = [this._btnInterior.children[0] as HTMLDivElement/* , this._btnContact.children[0] as HTMLDivElement */];
    }

    show(delay?: number, complete?: Function): number {
        this._cont.style.display = "block";

        delay = delay || 0;

        this._desc.classList.remove('hide');
        anime.remove(this._desc);
        anime({
            targets: this._desc, opacity: [0, 1], delay, duration: 350, easing: "linear"
        });

        anime.remove(this._descChilds);
        anime({
            targets: this._descChilds, opacity: [0, 1], translateX: ["-15%", 0],
            delay: anime.stagger(100, { start: delay }),
            duration: 500, easing: "easeOutQuad",
        });

        anime.remove(this._title);
        anime({
            targets: this._title, opacity: [0, 1], delay: delay + 50, duration: 500, easing: "linear"
        });

        anime.remove(this._360ico);
        anime({
            targets: this._360ico, opacity: [0, 1], delay: delay + 125, duration: 500, easing: "linear"
        });

        anime.remove(this._btnDivs);
        this._btnDivs.forEach(btn => (btn as HTMLElement).style.pointerEvents = "none");
        anime({
            targets: this._btnDivs, opacity: [0, 1], delay: delay + 650, duration: 400, easing: "linear",
            complete: () => {
                this._btnDivs.forEach(btn => (btn as HTMLElement).style.pointerEvents = "");
                app.ui.showHideHeaderBtns();
                complete && complete();
            }
        });

        this._exterior.animToFrameIndex(this._exterior.totalFrameCount + this._exterior.defaultFrameIndex, 500)?.
            finished.then(() => {
                this._exterior.enabled = true;
                this._exterior.setPins();
            });

        app.ui.carSubMenu.setAmbience();
        app.sendToGTM(this._data.title);

        return 0;
    }

    hide(delay?: number, complete?: Function): number {
        anime.remove(this._descChilds);
        /* anime({
            targets: this._descChilds, opacity: 0, translateX: 0,
            delay: anime.stagger(50, { start: delay, from: "last" }),
            duration: 300, easing: "easeOutQuad",
            complete: () => this._cont.style.display = "none"
        }); */

        anime.remove(this._360ico);
        anime.remove(this._title);

        anime.remove(this._btnDivs);
        this._btnDivs.forEach(btn => (btn as HTMLElement).style.pointerEvents = "none");
        anime({
            targets: [this._desc, this._360ico, this._title, ...this._btnDivs],
            opacity: 0, duration: 350, easing: "linear",
            complete: () => {
                this._cont.style.display = "none"
            }
        });

        this._exterior.enabled = false;
        this._exterior.resetToDefault();
        this._exterior.setPins();
        app.ui.showHideHeaderBtns(true);

        return 200;
    }
}