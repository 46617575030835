import { IDataCar } from ".";

export default {
    id: "car_308",
    urlPageId: "308",
    title: "PEUGEOT 308",
    shortTitle: "308",
    colors: [
        { colorHex: "#1f4c2b", title: "Yeşil (Olivin)", dir: "yesil", featured: true },
        { colorHex: "#800", title: "Kırmızı (Elixir)", dir: "kirmizi" },
        { colorHex: "#010a99", title: "Mavi (Vertigo)", dir: "mavi" },
        { colorHex: "#707070", title: "Gri (Tekno)", dir: "gri" },
        { colorHex: "#fff", title: "Beyaz (Sedef)", dir: "beyaz" },
        { colorHex: "#101010", title: "Siyah (İnci)", dir: "siyah" },
    ],
    infoLink: "https://www.peugeot.com.tr/peugeot-modelleri/yeni-308.html",
    otherPackagesLink: "https://www.peugeot.com.tr/peugeot-modelleri/yeni-308.html",
    broshureLink: "/assets/files/308-KATALOG.pdf",
    appointmentLink: '308/hb',
    galleryPhotos: [
        { source: "gallery_0.jpg" }, { source: "gallery_1.jpg" }, { source: "gallery_2.jpg" }, { source: "gallery_3.jpg" },
        { source: "gallery_4.jpg" }, { source: "gallery_5.jpg" }, { source: "gallery_6.jpg" }, { source: "gallery_7.jpg" },
        { source: "gallery_8.jpg" }, { source: "gallery_9.jpg" }
    ],
    videos: [
        { title: "Yeni PEUGEOT 308 - Eşsiz Deneyim", type: "youtube", source: "8ecxC_tAhfY" },
        { title: "Yeni PEUGEOT 308", type: "youtube", source: "c8WpgBhCk1o" },
    ],
    interiorImageNames: [
        "interior_0", "interior_1", "interior_2", "interior_3", "interior_4", "interior_5_{color}",
    ],
    pinsInterior: [
        {
            id: "cockpit", coords: { x: 71.5, y: -12.5 },
            speechKeywords: "3D, gösterge, panel, gösterge paneli, ön gösterge paneli, dijital gösterge, navigasyon, radyo",
        }, {
            id: "screen", coords: { x: 91.5, y: -20 },
            speechKeywords: "dokunmatik, multimedya, ekran, dokunmatik multimedya ekranı, mirror screen, i toggles, profil seçimi, kullanım klavuzu, ambiyans, ambiyans aydınlatması",
        }, {
            id: "midconsole", coords: { x: 90, y: -45 },
            speechKeywords: "orta, konsol, orta konsol, orta bölüm, e toggle, vites, kablosuz şarj, şarj, sürüş modları, spor mod, tavan, cam tavan, ön koltuklar, ısıtmalı koltuklar, bagaj, bagaj hacmi, yatırılabilir",
        }, {
            id: "backseats", coords: { x: -88.5, y: -55.4 },
            speechKeywords: "arka, oturma alanı, geniş arka oturma alanı, arka koltuklar, diz mesafesi, kol dayama, havalandırma, okuma lambaları",
        }, {
            id: "trunk-i", coords: { x: -88.5, y: -20.3 },
            speechKeywords: "bagaj, bagaj hacmi, yatırılabilir",
        },
    ],
    featuredPinFrameIndex: 2,
    pinFrames: [
        { index: 0, pins: [{ id: "lights", posXPerc: 43.67, posYPerc: 54.85 }, { id: "mirrors", posXPerc: 36.89, posYPerc: 44.01 }, { id: "frontgrid", posXPerc: 57.78, posYPerc: 60.01 },] },
        { index: 1, pins: [{ id: "lights", posXPerc: 49.22, posYPerc: 55.01 }, { id: "mirrors", posXPerc: 38.78, posYPerc: 43.68 }, { id: "frontgrid", posXPerc: 63.56, posYPerc: 60.01 },] },
        { index: 2, pins: [{ id: "lights", posXPerc: 63.56, posYPerc: 54.35 }, { id: "mirrors", posXPerc: 42.89, posYPerc: 43.68 }, { id: "frontgrid", posXPerc: 75.78, posYPerc: 57.35 }, { id: "sidelogo", posXPerc: 47.57, posYPerc: 51.85 }, { id: "tire", posXPerc: 52.12, posYPerc: 63.66 }, { id: "sideextender", posXPerc: 37.46, posYPerc: 61.83 },] },
        { index: 3, pins: [{ id: "lights", posXPerc: 72.22, posYPerc: 53.19 }, { id: "mirrors", posXPerc: 48, posYPerc: 43.68 }, { id: "frontgrid", posXPerc: 80.67, posYPerc: 56.68 }, { id: "tire", posXPerc: 59.67, posYPerc: 63.35 }, { id: "sideextender", posXPerc: 41.57, posYPerc: 63.33 }, { id: "sidelogo", posXPerc: 54.46, posYPerc: 52.18 },] },
        { index: 4, pins: [{ id: "lights", posXPerc: 78, posYPerc: 52.02 }, { id: "backlights", posXPerc: 18.22, posYPerc: 45.01 }, { id: "mirrors", posXPerc: 54.11, posYPerc: 43.84 }, { id: "tire", posXPerc: 67, posYPerc: 62.19 }, { id: "sidelogo", posXPerc: 61.11, posYPerc: 51.68 }, { id: "sideextender", posXPerc: 43.33, posYPerc: 63.02 },] },
        { index: 5, pins: [{ id: "lights", posXPerc: 80.11, posYPerc: 51.85 }, { id: "backlights", posXPerc: 17.33, posYPerc: 45.68 }, { id: "mirrors", posXPerc: 57.89, posYPerc: 44.18 }, { id: "tire", posXPerc: 70.22, posYPerc: 62.02 }, { id: "sidelogo", posXPerc: 64.89, posYPerc: 51.68 }, { id: "sideextender", posXPerc: 47.78, posYPerc: 64.35 },] },
        { index: 6, pins: [{ id: "backlights", posXPerc: 21.67, posYPerc: 47.17 }, { id: "mirrors", posXPerc: 63.22, posYPerc: 43.84 }, { id: "tire", posXPerc: 73.67, posYPerc: 60.68 }, { id: "sidelogo", posXPerc: 69, posYPerc: 51.18 }, { id: "sideextender", posXPerc: 54.11, posYPerc: 64.02 },] },
        { index: 7, pins: [{ id: "backlights", posXPerc: 26.22, posYPerc: 47.17 }, { id: "mirrors", posXPerc: 65.44, posYPerc: 44.34 }, { id: "tire", posXPerc: 74.11, posYPerc: 59.85 }, { id: "sidelogo", posXPerc: 70.56, posYPerc: 50.68 }, { id: "sideextender", posXPerc: 57.11, posYPerc: 64.68 },] },
        { index: 8, pins: [{ id: "backlights", posXPerc: 35.78, posYPerc: 48.01 }, { id: "mirrors", posXPerc: 66, posYPerc: 43.68 }, { id: "tire", posXPerc: 44.78, posYPerc: 64.35 }, { id: "sidelogo", posXPerc: 68.89, posYPerc: 50.18 }, { id: "sideextender", posXPerc: 59.89, posYPerc: 63.35 }, { id: "trunk", posXPerc: 24.01, posYPerc: 48.85 },] },
        { index: 9, pins: [{ id: "backlights", posXPerc: 41.89, posYPerc: 48.17 }, { id: "mirrors", posXPerc: 65.78, posYPerc: 43.84 }, { id: "tire", posXPerc: 50, posYPerc: 64.68 }, { id: "sideextender", posXPerc: 61.67, posYPerc: 63.02 }, { id: "trunk", posXPerc: 28.9, posYPerc: 50.69 },] },
        { index: 10, pins: [{ id: "backlights", posXPerc: 50.22, posYPerc: 48.34 }, { id: "mirrors", posXPerc: 66.56, posYPerc: 43.01 }, { id: "tire", posXPerc: 56.33, posYPerc: 64.02 }, { id: "trunk", posXPerc: 35.23, posYPerc: 50.53 },] },
        { index: 11, pins: [{ id: "backlights", posXPerc: 36.44, posYPerc: 47.18 }, { id: "trunk", posXPerc: 49.45, posYPerc: 51.52 }, { id: "mirrors", posXPerc: 63.12, posYPerc: 42.53 },] },
        { index: 12, pins: [{ id: "backlights", posXPerc: 50.33, posYPerc: 48.01 }, { id: "trunk", posXPerc: 63.57, posYPerc: 50.87 }, { id: "mirrors", posXPerc: 34.34, posYPerc: 42.7 },] },
        { index: 13, pins: [{ id: "backlights", posXPerc: 57.67, posYPerc: 48.68 }, { id: "mirrors", posXPerc: 35.11, posYPerc: 43.01 }, { id: "trunk", posXPerc: 70.34, posYPerc: 50.2 }, { id: "tire", posXPerc: 49.12, posYPerc: 64.49 },] },
        { index: 14, pins: [{ id: "backlights", posXPerc: 63.67, posYPerc: 47.84 }, { id: "mirrors", posXPerc: 34.11, posYPerc: 43.01 }, { id: "tire", posXPerc: 54.11, posYPerc: 64.18 }, { id: "trunk", posXPerc: 75.67, posYPerc: 49.7 }, { id: "sideextender", posXPerc: 41.01, posYPerc: 62.83 }, { id: "sidelogo", posXPerc: 31.12, posYPerc: 49.02 },] },
        { index: 15, pins: [{ id: "backlights", posXPerc: 74.33, posYPerc: 47.34 }, { id: "mirrors", posXPerc: 35.78, posYPerc: 43.17 }, { id: "tire", posXPerc: 64.45, posYPerc: 63.36 }, { id: "sidelogo", posXPerc: 31.23, posYPerc: 49.68 }, { id: "sideextender", posXPerc: 47.01, posYPerc: 63.49 },] },
        { index: 16, pins: [{ id: "backlights", posXPerc: 77.89, posYPerc: 46.84 }, { id: "mirrors", posXPerc: 35.89, posYPerc: 43.34 }, { id: "tire", posXPerc: 67.33, posYPerc: 63.02 }, { id: "sidelogo", posXPerc: 30.06, posYPerc: 50.67 }, { id: "sideextender", posXPerc: 46.33, posYPerc: 64.18 },] },
        { index: 17, pins: [{ id: "backlights", posXPerc: 82, posYPerc: 46 }, { id: "mirrors", posXPerc: 40.67, posYPerc: 43.84 }, { id: "tire", posXPerc: 73.22, posYPerc: 61.52 }, { id: "sidelogo", posXPerc: 33.89, posYPerc: 51.01 }, { id: "sideextender", posXPerc: 50.56, posYPerc: 64.35 }, { id: "lights", posXPerc: 19.46, posYPerc: 50.85 },] },
        { index: 18, pins: [{ id: "lights", posXPerc: 22, posYPerc: 51.68 }, { id: "mirrors", posXPerc: 45.89, posYPerc: 43.68 }, { id: "tire", posXPerc: 32.33, posYPerc: 62.18 }, { id: "sidelogo", posXPerc: 38.22, posYPerc: 51.51 }, { id: "sideextender", posXPerc: 55.22, posYPerc: 63.68 }, { id: "backlights", posXPerc: 79.68, posYPerc: 45.02 },] },
        { index: 19, pins: [{ id: "lights", posXPerc: 25.11, posYPerc: 52.18 }, { id: "mirrors", posXPerc: 49.11, posYPerc: 43.68 }, { id: "tire", posXPerc: 35.89, posYPerc: 63.35 }, { id: "sidelogo", posXPerc: 41.56, posYPerc: 51.18 }, { id: "sideextender", posXPerc: 55.78, posYPerc: 64.02 },] },
        { index: 20, pins: [{ id: "lights", posXPerc: 37.89, posYPerc: 53.35 }, { id: "mirrors", posXPerc: 56.22, posYPerc: 43.84 }, { id: "tire", posXPerc: 46.56, posYPerc: 64.34 }, { id: "sidelogo", posXPerc: 51, posYPerc: 52.34 }, { id: "sideextender", posXPerc: 61.22, posYPerc: 62.52 }, { id: "frontgrid", posXPerc: 23.34, posYPerc: 56.34 },] },
        { index: 21, pins: [{ id: "lights", posXPerc: 49.67, posYPerc: 54.01 }, { id: "mirrors", posXPerc: 60.56, posYPerc: 44.01 }, { id: "frontgrid", posXPerc: 33.33, posYPerc: 58.68 }, { id: "tire", posXPerc: 55.33, posYPerc: 64.18 },] },
        { index: 22, pins: [{ id: "lights", posXPerc: 56, posYPerc: 54 }, { id: "mirrors", posXPerc: 62.78, posYPerc: 43.84 }, { id: "frontgrid", posXPerc: 40, posYPerc: 59 },] },
        { index: 23, pins: [{ id: "lights", posXPerc: 64, posYPerc: 54.68 }, { id: "mirrors", posXPerc: 64.78, posYPerc: 43.84 }, { id: "frontgrid", posXPerc: 49.44, posYPerc: 60.51 },] },
    ]
} as IDataCar;