import anime from "animejs";
import { app, STATIC_IMAGE_URL } from "..";
import { APPOINTMENT_LINK, DataCarList } from "../data";
import { UIEvents } from "../ui";
import { UIViewType } from "./uiViews";

export default class UICarSubMenu {
    private _cont: HTMLElement;
    private _buttons: NodeListOf<HTMLButtonElement>;
    private _buttonsDiv: NodeListOf<HTMLElement>;
    private _btnNext: HTMLButtonElement;
    private _btnPrev: HTMLButtonElement;
    private _btnMenuAmbience: HTMLElement;
    private _platformMenu: HTMLElement;

    get cont() { return this._cont; }

    constructor() {
        this._cont = app.ui.cont.querySelector(".car-submenu")!;
        this._platformMenu = app.ui.cont.querySelector(".platform-menu.car")!;

        (this._buttons = this._cont.querySelectorAll("button")).forEach(item => {
            item.addEventListener("click", () => this.onBtnClick(item));

            if (item.dataset['type'] == UIViewType.Ambience)
                this._btnMenuAmbience = item;
        });

        this._buttonsDiv = this._cont.querySelectorAll("button>div");

        this._btnPrev = this._platformMenu.querySelector("button.prev")!;
        this._btnPrev.addEventListener("click", () => this.onBtnClick(this._btnPrev))
        this._btnNext = this._platformMenu.querySelector("button.next")!;
        this._btnNext.addEventListener("click", () => this.onBtnClick(this._btnNext))

        app.ui.on(UIEvents.CarChanged, () => this._onCarChanged());
    }

    toggleButtonsEnable(selectedType: UIViewType = UIViewType.None) {
        this._buttons.forEach(item => {
            item.classList.remove("disabled", "selected");

            if (selectedType != UIViewType.None) {
                if (item.dataset["type"] != selectedType)
                    item.classList.add("disabled");
                else
                    item.classList.add('selected');
            }
        });

        switch (selectedType) {
            case UIViewType.Colors:
            case UIViewType.GeneralInfo:
            case UIViewType.PriceInfo:
            case UIViewType.Video:
            case UIViewType.Gallery:
                this._cont.style.zIndex = "10";
                break;
            default:
                this._cont.style.zIndex = "";
                break;
        }
    }

    show(delay: number = 0) {
        this._cont.style.display = "flex";
        this._platformMenu.style.display = "block";

        anime({
            targets: [this._buttonsDiv, this._platformMenu],
            translateY: ["50%", 0], opacity: [0, 1],
            duration: 400, easing: "easeOutQuad",
            delay: anime.stagger(100, { start: delay + 350, from: "center" })
        });
    }

    hide(delay: number = 0) {
        anime({
            targets: [this._buttonsDiv, this._platformMenu],
            translateY: "50%", opacity: 0,
            duration: 350, easing: "easeOutQuad",
            delay: anime.stagger(50, { start: delay, from: "center" }),
            complete: () => {
                this._platformMenu.style.display = "none";
                this._cont.style.display = "none";
            }
        });
    }

    onBtnClick(elm: HTMLElement) {
        if (this._cont.style.display == "none")
            return;

        const type = elm.dataset["type"] as UIViewType;
        let i;

        switch (type) {
            case UIViewType.Info:
                window.open(app.ui.car!.data.infoLink);
                break;
            case UIViewType.Broshure:
                window.open(app.ui.car!.data.broshureLink);
                break;
            case UIViewType.OtherPackages:
                window.open(app.ui.car!.data.otherPackagesLink);
                break;
            case UIViewType.Lights:
                app.ui.car!.exterior.lightsEnabled = !app.ui.car!.exterior.lightsEnabled;
                break;
            case UIViewType.Appointment:
                window.open(APPOINTMENT_LINK.replace(/{car_path}/, app.ui.car?.data.appointmentLink || ""));
                break;
            case UIViewType.Link:
                window.open(elm.dataset["link"]);
                break;
            case UIViewType.Ambience:
                app.platform.cycleAmbienceLights();
                break;
            case UIViewType.PrevCar:
                i = app.ui.car!.index - 1;
                if (i < DataCarList.length)
                    app.platform.zoomOut(() => app.platform.zoomInTo(i));
                break;
            case UIViewType.NextCar:
                i = app.ui.car!.index + 1;
                if (i >= 0)
                    app.platform.zoomOut(() => app.platform.zoomInTo(i));
                break;
            default:
                app.ui.views.showView(type);
                break;
        }
    }

    setAmbience(hide?: boolean) {
        this._btnMenuAmbience.style.display = hide || !app.ui.car?.data.ambienceColors ? "none" : "inline-block";
        app.platform.cycleAmbienceLights(this._btnMenuAmbience.style.display == "none");
    }

    private _onCarChanged() {
        if (!app.ui.car)
            return;

        this._btnPrev.style.display = "none";
        this._btnNext.style.display = "none";

        const prevCarData = DataCarList[app.ui.car.index - 1],
            nextCarData = DataCarList[app.ui.car.index + 1];

        if (prevCarData) {
            this._btnPrev.style.display = "block";
            this._btnPrev.querySelector<HTMLSpanElement>("span")!.innerHTML = prevCarData.shortTitle;
            this._btnPrev.querySelector<HTMLImageElement>("img")!.src = `${STATIC_IMAGE_URL + prevCarData.id}/thumbnail.png`;
        }
        if (nextCarData) {
            this._btnNext.style.display = "block";
            this._btnNext.querySelector<HTMLSpanElement>("span")!.innerHTML = nextCarData.shortTitle;
            this._btnNext.querySelector<HTMLImageElement>("img")!.src = `${STATIC_IMAGE_URL + nextCarData.id}/thumbnail.png`;
        }
    }
}