import { EventEmitter } from "eventemitter3";
import { appUtils } from ".";

export enum LoaderEvents { Progress = "progress", Complete = "complete" }

type File = { name?: string, file: string };

const _MAX_SIM_FILE_COUNT = 4;
export default class Loader extends EventEmitter<LoaderEvents> {
    private _resources: { [id: string]: HTMLImageElement } = {};
    private _files: File[];
    private _totalFileLength: number;
    private _loadingQueue: number;
    private _ui: HTMLElement;
    private _timeout: NodeJS.Timeout;
    private _autoHideUI: boolean;

    get resources() { return this._resources; }

    constructor(files?: File[]) {
        super();
        this._files = files || [];
        this._ui = document.getElementById("loading")!;
    }

    add(file: string, name?: string) {
        this._files.push({ file, name });
        return this;
    }

    load(autoHideUI: boolean = true) {
        this._autoHideUI = autoHideUI;
        this._totalFileLength = this._files.length;
        this._loadingQueue = 0;

        this.showUI();

        const l = Math.min(this._totalFileLength, _MAX_SIM_FILE_COUNT);
        for (let i = 0; i < l; i++)
            this._loadItem();

        return this;
    }

    showUI() {
        this._ui.style.display = "block";

        clearTimeout(this._timeout);
        if (this._ui.style.opacity != "1")
            this._timeout = setTimeout(() => this._ui.style.opacity = "1", 1000);
        return this;
    }

    hideUI() {
        clearTimeout(this._timeout);
        if (this._ui.style.opacity == "0")
            this._ui.style.display = "none"
        else {
            this._ui.style.opacity = "0";
            this._timeout = setTimeout(() => this._ui.style.display = "none");
        }
        return this;
    }

    private _onComplete() {
        this.emit(LoaderEvents.Complete);
        if (this._autoHideUI)
            this.hideUI();
    }

    private _loadItem() {
        let perc: number = 1 - this._files.length / this._totalFileLength;
        this.emit(LoaderEvents.Progress, perc);

        if (this._files.length == 0) {
            if (this._loadingQueue <= 0)
                this._onComplete();
            return;
        }

        const file = this._files.shift()!,
            name = (!appUtils.nullOrEmpty(file.name) ? file.name : file.file.match(/^(.+?)(?:\.[^.]*$|$)/)![1])!;
        if (this._resources[name]) {
            console.warn("duplicate! skipped...", name);
            this._loadItem();
            return;
        }

        this._loadingQueue++;

        const img = new Image();
        img.src = "/assets/" + file.file;
        img.onload = () => {
            this._resources[name] = img;
            this._loadingQueue--;
            this._loadItem();
        }
        img.onerror = () => {
            console.error(name);
            this._loadingQueue--;
            this._loadItem();
        }
    }
}