import anime from "animejs";
import { EventEmitter } from "eventemitter3";
import { app, appUtils } from "..";
import { AppEvents } from "../app";
import { IDataCar, TypeDataCarPin, TypeDataCarPinFrame } from "../data";
import { LoaderEvents } from "../loader";
import Swiper, { SwiperEventObj, SwiperEvents } from "./swiper";
import { UIViewsEvents, UIViewType } from "./uiViews";

export enum CarExteriorEvents {
    None = "none",
    FrameChange = "framechange",
    ColorChange = "colorchange",
}

export default class CarExterior extends EventEmitter<CarExteriorEvents> {

    private _cont: HTMLElement;
    private _frameIndex: number;
    private _cvs: HTMLCanvasElement;
    private _ctx: CanvasRenderingContext2D;
    private _animating: boolean;
    private _enabled: boolean;
    private _swipeStartIndex: number = 0;
    private _data: IDataCar;
    private _swiper: Swiper;
    private _pinCont: HTMLUListElement;
    private _selectedPin: { dom?: HTMLElement, data?: TypeDataCarPin } | null;
    private _lightsEnabled: boolean = true;
    private _colorIndex: number = 0;
    private _totalFrameCount: number = 0;
    private _defaultFrameIndex: number = 0;

    get totalFrameCount() { return this._totalFrameCount; }
    get defaultFrameIndex() { return this._defaultFrameIndex; }
    get pin() { return this._selectedPin; }
    get cont() { return this._cont; }
    get frameIndex() { return this._frameIndex; }
    get colorIndex() { return this._colorIndex; }
    get color() { return this._data.colors[this._colorIndex]; }
    get enabled() { return this._enabled; }
    set enabled(v: boolean) { this._enabled = this._swiper.enabled = v; }
    get lightsEnabled() { return this._lightsEnabled && !this._data.noCarLights; }
    set lightsEnabled(v: boolean) { this._lightsEnabled = v; this._setFrameIndex(this._frameIndex, true); }

    constructor(data: IDataCar, pinCont: HTMLUListElement) {
        super();

        this._data = data;
        this._pinCont = pinCont;
        this._cont = app.platform.stands.querySelector(`.${this._data.id}`)!;
        this._cont.appendChild(this._cvs = document.createElement("canvas") as HTMLCanvasElement);
        this._ctx = this._cvs.getContext('2d')!;
        // this._ctx.imageSmoothingEnabled = false;

        this._colorIndex = this._data.colors.findIndex(clr => clr.featured == true) || 0;

        this._totalFrameCount = this._data?.pinFrames.length || 36;
        this._defaultFrameIndex = this._data.featuredPinFrameIndex || 3;

        // initial frame load
        app.loader.add(`platform/${this._data.id}/${this.color.dir}/car_${this._defaultFrameIndex}.png`);
        app.loader.add(`platform/${this._data.id}/${this.color.dir}/car_${this._defaultFrameIndex}_lights.png`);
        app.loader.once(LoaderEvents.Complete, () => this._initialLoadDone());
    }

    private _initialLoadDone() {
        if (this._defaultFrameIndex < 0) {
            console.error('load failed or default frame not found!', this._defaultFrameIndex)
            return;
        }

        const img = this._getResImage(this._defaultFrameIndex);
        this._cvs.width = img.width;
        this._cvs.height = img.height;

        this._setFrameIndex(this._defaultFrameIndex);

        (this._swiper = new Swiper(this._cont))
            .on(SwiperEvents.SwipeStart, e => this._onSwipeStart(e))
            .on(SwiperEvents.Swiping, e => this._onSwiping(e))

        this._swiper.enabled = false;

        app.on(AppEvents.Resize, () => this.setPins());
        app.ui.views.on(UIViewsEvents.ViewShow, () => this._onViewShow());
        app.ui.views.on(UIViewsEvents.ViewHideded, () => this._onViewHided());
        this.setPins();
    }

    resetToDefault(delay: number = 0, maxDuration: number = 1000) {
        const halfTotalFrames = this._totalFrameCount / 2 - 1,
            index = this._defaultFrameIndex - this._frameIndex < -halfTotalFrames ? (this._totalFrameCount + this._defaultFrameIndex) : this._defaultFrameIndex,
            duration = (Math.abs(this._frameIndex - index) / halfTotalFrames) * maxDuration;
        this.animToFrameIndex(index, delay, duration);
    }

    animToFrameIndex(index: number = 0, delay: number = 0, duration: number = 1000) {
        if (this._frameIndex == index || this._animating)
            return undefined;

        const objFrame = { value: this._frameIndex };
        anime.remove(objFrame);
        return anime({
            targets: objFrame,
            value: index, easing: "easeInOutSine", duration, delay,
            update: () => this._setFrameIndex(objFrame.value),
            complete: () => this._animating = false
        });
    }

    changeColor(index: number) {
        const newIndex = appUtils.clamp(index, 0, this._data.colors.length - 1);
        if (this._data.colors[newIndex].disabled != true) {
            this._colorIndex = newIndex;
            this.load();
            this.emit(CarExteriorEvents.ColorChange);
        }
    }

    load(complete?: Function) {
        for (let i = 0; i < this._totalFrameCount; i++) {
            app.loader.add(`platform/${this._data.id}/${this.color.dir}/car_${i}.png`);
            app.loader.add(`platform/${this._data.id}/${this.color.dir}/car_${i}_lights.png`);
        }
        app.loader.once(LoaderEvents.Complete, () => {
            this._setFrameIndex(this._frameIndex, true);
            complete && complete();
        }).load();
    }

    private _setFrameIndex(index: number, force: boolean = false) {
        let newIndex = Math.abs(Math.round(index) % this._totalFrameCount);
        if (index < 0) newIndex = this._totalFrameCount - 1 - newIndex;

        const isNew = newIndex != this._frameIndex;
        if (!force && !isNew)
            return;

        this._frameIndex = newIndex;
        this._ctx.clearRect(0, 0, this._cvs.width, this._cvs.height);
        // this._ctx.translate(.5, .5);
        this._ctx.drawImage(this._getResImage(this._frameIndex), 0, 0, this._cvs.width, this._cvs.height);
        // this._ctx.translate(-.5, -.5);

        this._cont.setAttribute("data-frameIndex", this._frameIndex.toString());

        if (isNew) {
            this.setPins();
            this.emit(CarExteriorEvents.FrameChange);
        }
    }

    private _getResImage(index: number) {
        return app.loader.resources[`platform/${this._data.id}/${this.color.dir}/car_${index}` +
            (this.lightsEnabled ? '_lights' : '')];
    }

    private _onSwipeStart(e: SwiperEventObj) {
        if (!this._enabled || this._animating)
            return;

        this._swipeStartIndex = this._frameIndex;
    }

    private _onSwiping(e: SwiperEventObj) {
        if (!this._enabled || this._animating)
            return;

        const percX = e.diff.x / window.innerWidth;
        this._setFrameIndex(this._swipeStartIndex + percX * (this._totalFrameCount - 1));
    }

    private _onViewShow() {
        if (this._selectedPin?.dom)
            this._selectedPin.dom.style.zIndex = "11";
    }

    private _onViewHided() {
        if (this._selectedPin?.dom)
            this._selectedPin.dom.style.zIndex = "";

        this._selectedPin = null;
    }

    selectPinById(id: string) {
        if (this._selectedPin && this._selectedPin.data?.id == id)
            return;

        let i, j, frame: TypeDataCarPinFrame = this._data.pinFrames.find(frame => frame.index == this._frameIndex)!,
            pin: TypeDataCarPin | null = frame.pins.find(pin => pin.id == id)!;

        if (appUtils.nullOrEmpty(pin)) {
            for (i = 0; i < this._data.pinFrames.length; i++) {
                frame = this._data.pinFrames[i];
                for (j = 0; j < frame.pins.length; j++) {
                    pin = frame.pins[j];
                    if (pin.id == id/*  && pin.featured == true */)
                        break;
                    pin = null;
                }
                if (pin)
                    break;
            }

            if (appUtils.nullOrEmpty(pin))
                return;
        }

        this._selectedPin = { data: pin! };
        this.setPins();
        this.animToFrameIndex(frame.index);
    }

    private _selectPin(pinDom: HTMLElement, data: TypeDataCarPin) {
        this._selectedPin = { dom: pinDom, data };
        app.ui.views.showView(UIViewType.CarSpecs);
    }

    private _tOutPins: NodeJS.Timeout;
    setPins() {
        this._pinCont.innerHTML = "";
        clearTimeout(this._tOutPins);

        if (!this.enabled)
            return;

        const setPins = () => {
            const carContBounds = this._cont.getBoundingClientRect();
            this._pinCont.style.width = carContBounds.width + "px";
            this._pinCont.style.height = carContBounds.height + "px";
            this._pinCont.style.left = carContBounds.x + "px";
            this._pinCont.style.top = carContBounds.y + "px";

            const pinFrame = this._data.pinFrames.find((val, index) => val.index == this._frameIndex);
            if (pinFrame) {
                let pinDom: HTMLLIElement;
                pinFrame.pins.forEach((pin, index) => {
                    pinDom = document.createElement("li");
                    pinDom.classList.add("button");
                    pinDom.style.left = pin.posXPerc + "%";
                    pinDom.style.top = pin.posYPerc + "%";
                    pinDom.setAttribute("data-id", pin.id);
                    pinDom.innerHTML = '<ul class="effect-water"><li></li><li></li><li></li></ul>';
                    pinDom.addEventListener("click", e => this._selectPin(e.currentTarget as HTMLElement, pin));
                    this._pinCont.appendChild(pinDom);

                    if (this._selectedPin && this._selectedPin.data?.id == pin.id) {
                        this._selectedPin.dom = pinDom;
                        this._onViewShow();
                    }
                });
            }
        }
        this._tOutPins = setTimeout(() => setPins(), 250);
    }
}