import { EventEmitter } from "eventemitter3";
import { app, appUtils, IPoint } from "..";
import { AppEvents } from "../app";
import Swiper, { SwiperEventObj, SwiperEvents } from "./swiper";

export enum PannerEvents {
    Panning = 'panning',
}

export default class Panner extends EventEmitter<PannerEvents> {
    private _new: IPoint = { x: 0, y: 0 };
    private _current: IPoint = { x: 0, y: 0 };
    private _percent: IPoint = { x: 0, y: 0 };
    private _swiper: Swiper;
    private _elm: HTMLElement;
    private _baseSizeElm?: HTMLElement;
    private _enabled: boolean = true;
    private _capLeftRight: { left: number; right: number; };
    private _panToCallback: Function | undefined;

    get current() { return this._current; }
    get percent() { return this._percent; }
    get inputEnabled() { return this._swiper.enabled; }
    set inputEnabled(v: boolean) { this._swiper.enabled = v; }
    get enabled() { return this._enabled; }
    set enabled(v: boolean) { this._enabled = this.inputEnabled = v; }
    get isPanning() { return this._current.x != this._new.x; }

    constructor(elm: HTMLElement, baseSizeElm?: HTMLElement, capLeftRight = { left: 1, right: 1 }) {
        super();

        this._elm = elm;
        this._baseSizeElm = baseSizeElm;
        this._capLeftRight = capLeftRight;

        (this._swiper = new Swiper(this._elm))
            .on(SwiperEvents.Swiping, e => this._onSwiping(e));

        app.on(AppEvents.Tick, () => this._tick());
        app.on(AppEvents.Resize, e => this._resize(e));
    }

    panTo(percentX?: number, force?: boolean, complete?: Function) {
        this._panToCallback = complete;

        if (percentX != undefined) {
            const xMinMax = this._getCapXMinMax();
            this._new.x = (percentX * (xMinMax.max - xMinMax.min)) + xMinMax.min;
        }

        if (force)
            this._doPanning(true);
    }

    private _tick() {
        if (!this.enabled)
            return;

        this._doPanning();
    }

    private _getCapXMinMax() {
        const min = Math.max(0, (this._elm.offsetWidth - window.innerWidth * .5) / window.innerWidth * 50) * this._capLeftRight.left,
            max = min + Math.max(0, (this._baseSizeElm!.offsetWidth) / window.innerWidth * 50) * this._capLeftRight.right;
        return { min: -min, max };
    }

    private _capValueX(x: number) {
        if (!this._baseSizeElm)
            return x;

        const minMax = this._getCapXMinMax();
        return appUtils.clamp(x, minMax.min, minMax.max);
    }

    private _resize(e: UIEvent) {
        this._new.x = this._capValueX(this._new.x);

        if (this.enabled)
            this._doPanning(true);
    }

    private _doPanning(force: boolean = false) {
        if (this._panToCallback && this._current.x == this._new.x) {
            this._panToCallback();
            this._panToCallback = undefined;
        }

        if (!force && this._current.x == this._new.x && this._current.y == this._new.y)
            return;

        if (!force)
            this._current.x = appUtils.lerp(this._current.x, this._new.x, .1);

        if (force || Math.abs(this._current.x - this._new.x) < .075)
            this._current.x = this._new.x;

        const xMinMax = this._getCapXMinMax();
        this._percent.x = (this._current.x - xMinMax.min) / (xMinMax.max - xMinMax.min);

        this.emit(PannerEvents.Panning);
    }

    private _onSwiping(e: SwiperEventObj) {
        if (!this.enabled || !this.inputEnabled)
            return;

        if (this._baseSizeElm?.offsetWidth! > this._elm.offsetWidth)
            this._new.x = this._capValueX(this._new.x + (e.velocity.x / window.innerWidth) * 100);
        else
            this._new.x += (e.velocity.x / this._elm.offsetWidth) * 100;
    }
}