import anime from "animejs";
import { app, appUtils } from "..";
import Gallery from "./gallery";
import UIView from "./uiView";
import { UIViewType } from "./uiViews";

export default class UIViewCarSpecs extends UIView {

    private _currentContent: HTMLElement;
    private _contentSecList: NodeListOf<HTMLElement>;
    private _galleryItems: Gallery[] = [];

    constructor() {
        super(app.ui.views.cont.querySelector("." + UIViewType.CarSpecs)!, UIViewType.CarSpecs);

        this._cont.querySelectorAll<HTMLElement>(".content>section").forEach(item => {
            item.querySelector(":scope>h1")?.addEventListener("click",
                () => this._selectSection(item.dataset["id"]!));
        });

        this._cont.querySelectorAll<HTMLElement>(".btn-close").forEach(item => {
            if (item != this._btnClose)
                item.addEventListener("click", () => app.ui.views.hideView());
        });

        this._initGalleryItems();
    }

    protected _onShow(complete: Function, endDelay: number = 0) {
        super._onShow(complete, endDelay);

        this._galleryItems.forEach(item => item.enabled = true);

        this._cont.querySelectorAll<HTMLElement>(":scope>div").forEach(item => {
            item.style.display = item.classList.contains(app.ui.car!.data.id) ? "block" : "none";
            if (item.style.display == "block")
                this._currentContent = item;
        });

        this._contentSecList = this._currentContent.querySelectorAll<HTMLElement>(".content>section");

        this._currentContent.querySelectorAll<HTMLElement>(":scope img[data-color-filter]").forEach(item => {
            item.classList.remove("selected");
            if (item.dataset["colorFilter"] == app.ui.car!.color.dir)
                item.classList.add("selected");
        });

        const pin = app.ui.car!.interior.enabled ? app.ui.car!.interior.pin : app.ui.car!.exterior.pin;

        if (app.ui.car!.interior.enabled) this._currentContent.classList.add("interior");
        else this._currentContent.classList.remove("interior");

        const floatLeft = pin!.dom!.offsetLeft > window.innerWidth / 2;
        if (floatLeft) this._currentContent.classList.add("float-left");
        else this._currentContent.classList.remove("float-left");

        this._selectSection("");

        let delay = 150;
        anime({
            targets: [this._currentContent, this._contentSecList],
            opacity: [0, 1], translateX: [floatLeft ? "-50%" : "50%", 0],
            duration: 400, delay: anime.stagger(50, { start: delay }), easing: "easeOutQuad",
        });

        setTimeout(() => this._selectSection(pin!.data!.id), 50);
    }

    hide(complete: Function): void {
        super.hide(complete);

        this._galleryItems.forEach(item => item.enabled = false);

        anime({
            targets: this._currentContent,
            opacity: 0, translateX: this._currentContent.classList.contains("float-left") ? "-50%" : "50%",
            duration: 500, easing: "easeInQuad",
        });
    }

    private _selectSection(id: string) {
        const [_id, galleryIndex] = id.split(':');

        if (!appUtils.nullOrEmpty(id)) {
            if (app.ui.car!.interior.enabled)
                app.ui.car!.interior.selectPinById(id);
            else
                app.ui.car!.exterior.selectPinById(id);
        }

        this._contentSecList.forEach(item => {
            const selected = item.dataset["id"] == _id;

            if (selected) item.classList.add("selected");
            else item.classList.remove("selected");

            // const div = item.querySelector<HTMLElement>(":scope>div");
            // if (div) div.style.height = selected ? (this._getChildrenHeight(div) / window.innerHeight * 100 + "vh") : "";
        });

        this._galleryItems.forEach(gItem => {
            if (gItem.customData?.specElm?.querySelector('section[class*=selected]')?.dataset["id"] == _id) {
                gItem.setIndex(parseInt(galleryIndex) || 0);
            }
        })
    }

    private _initGalleryItems() {
        this._galleryItems = [];
        this._cont.querySelectorAll<HTMLElement>("div.gallery-module").forEach(item => {
            this._galleryItems.push(new Gallery(item, undefined, { specElm: item.closest('div[class*=car_]') }));
        });
    }

    private _getChildrenHeight(elm: HTMLElement) {
        let h = 0;
        for (let i = 0; i < elm.children.length; i++)
            h += (elm.children[i] as HTMLElement).offsetHeight;
        return h;
    }
}