import anime from "animejs";
import { app } from "..";
import { IUISec } from "../ui";

export default class UISecEntrance implements IUISec {
    private _cont: HTMLElement;
    private _childs: NodeListOf<HTMLElement>;
    private _buttons: NodeListOf<HTMLElement>;
    private _animating: boolean;

    constructor() {
        this._cont = app.ui.cont.querySelector('.entrance')!;
        this._childs = this._cont.querySelectorAll(":scope> *");

        this._cont.querySelector<HTMLElement>(":scope>button")!
            .addEventListener("click", () => !this._animating && app.startEnterLoad());

        (this._buttons = this._cont.querySelectorAll(":scope>div>button")).forEach(item => {
            item.addEventListener("click", () => !this._animating && app.ui.carSubMenu.onBtnClick(item));
        });
    }

    show(delay: number = 0, complete?: Function) {
        this._animating = true;
        this._cont.style.display = "block";
        anime.remove(this._childs);
        anime.remove(this._cont);
        anime({
            targets: this._cont, opacity: [0, 1],
            duration: 500, easing: "linear",
        });
        anime({
            targets: this._childs, opacity: [0, 1], translateY: ["4rem", 0],
            delay: anime.stagger(100, { start: delay + 100 }),
            duration: 600, easing: "easeOutQuad",
            complete: () => this._animating = false
        });

        return 0;
    }

    hide(delay: number = 0, complete?: Function) {
        this._animating = true;
        anime.remove(this._childs);
        anime.remove(this._cont);
        anime({
            targets: this._cont, opacity: 0, duration: 500, easing: "easeOutQuad",
            complete: () => {
                this._animating = false;
                this._cont.style.display = "none";
                complete && complete();
            }
        });

        return 0;
    }
}