import anime from "animejs";
import { app, appUtils, IPoint } from "..";
import { AppEvents } from "../app";
import { LoaderEvents } from "../loader";
import * as THREE from 'three';
import Swiper, { SwiperEventObj, SwiperEvents } from "./swiper";
import { IDataCar, TypeDataCarPin } from "../data";
import { UIViewsEvents, UIViewType } from "./uiViews";
import { MeshBasicMaterial, Vector3 } from "three";

const _GEOMETRY_RADIUS = 500;

export default class CarInterior {

    private _cont: HTMLElement;
    private _animating: boolean;
    private _interiorResourceNames: string[];
    private _btnClose: HTMLButtonElement;
    private _scene: THREE.Scene;
    private _camera: THREE.PerspectiveCamera;
    private _renderer: THREE.WebGLRenderer;
    // equirect: private _mesh: THREE.Mesh<THREE.SphereGeometry, THREE.MeshBasicMaterial>;
    private _mesh: THREE.Mesh<THREE.BoxGeometry, THREE.Material[]>;
    private _swiper: Swiper;
    private _camLookValues = { newLat: 0, lat: 0, newLon: 0, lon: 0 };
    private _pinCont: HTMLUListElement;
    private _selectedPin?: { dom: HTMLElement, data: TypeDataCarPin };
    private _data: IDataCar;
    private _canvasHalfSize: IPoint;
    private _frustum: THREE.Frustum;
    private _btnSpeech: HTMLButtonElement;
    private _speechRecognition: SpeechRecognition;

    get pin() { return this._selectedPin; }
    get cont() { return this._cont; }
    get enabled() { return this._renderer.domElement.style.display == "block"; }

    constructor(data: IDataCar, pinCont: HTMLUListElement) {
        this._data = data;
        this._cont = document.getElementById("canvas-container")!;
        this._pinCont = pinCont;
        (this._btnClose = this._cont.querySelector(".btn-close")!)
            .addEventListener("click", () => this.hide());

        this._initSpeechRecognition();

        this._init3D();
        (this._swiper = new Swiper(this._cont, { threshold: 0, mouseLeaveEvent: false }))
            .on(SwiperEvents.Swiping, e => this._onSwiping(e));
        this._swiper.enabled = false;

        app.on(AppEvents.Tick, () => this._tick());
        app.on(AppEvents.Resize, () => this._resize());
        app.ui.views.on(UIViewsEvents.ViewShow, () => this._onViewShow());
        app.ui.views.on(UIViewsEvents.ViewHideded, () => this._onViewHided());
    }

    show() {
        if (this._animating)
            return;

        app.ui.car!.exterior.enabled = false;

        const path = `platform/${this._data.id}/`;
        this._interiorResourceNames = [];
        this._data.interiorImageNames.forEach(item => {
            const file = path + item.replace("{color}", app.ui.car!.color.dir);
            this._interiorResourceNames.push(file);
            app.loader.add(file + ".jpg");
        });

        app.loader.once(LoaderEvents.Complete, () => this._loadDone())
            .load();
    }

    private _loadDone() {
        this._animating = true;
        this._pinCont.innerHTML = "";

        const mats: THREE.Material[] = [];
        let tex;
        this._interiorResourceNames.forEach(item => {
            tex = new THREE.Texture(app.loader.resources[item]);
            tex.needsUpdate = true;
            mats.push(new THREE.MeshBasicMaterial({ map: tex }));
        });
        this._mesh.material = mats;

        this._camLookValues.lon = this._camLookValues.newLon = 90;
        this._camLookValues.lat = this._camLookValues.newLat = -20;

        this._renderer.domElement.style.display = "block";
        this._cont.style.display = "block";
        this._resize();
        this._tick(true);
        anime({
            targets: this._cont,
            opacity: [0, 1], scale: [1.25, 1], duration: 600, easing: "easeInOutQuad",
            complete: () => {
                this._animating = false;
                this._swiper.enabled = true;
                this._pinCont.style.display = "block";
            }
        });
    }

    hide() {
        this._animating = true;
        this._swiper.enabled = false;
        this._pinCont.style.display = "none";
        this._stopSpeech();
        anime({
            targets: this._cont,
            opacity: 0, scale: 1.25, duration: 600, easing: "easeInOutQuad",
            complete: () => {
                this._animating = false;
                this._renderer.domElement.style.display = "none";
                this._cont.style.display = "none";
                app.ui.car!.exterior.enabled = true;
            }
        });
    }

    private _init3D() {
        this._scene = new THREE.Scene();
        this._camera = new THREE.PerspectiveCamera(70, 1, 0.1, 1000);
        this._renderer = new THREE.WebGLRenderer({ antialias: true });
        // this._renderer.outputColorSpace = THREE.LinearSRGBColorSpace; // INFO for new threejs version
        this._cont.appendChild(this._renderer.domElement);
        this._renderer.domElement.style.display = "none";

        // equirect: const geometry = new THREE.SphereGeometry(_GEOMETRY_RADIUS, 60, 40);
        const geometry = new THREE.BoxGeometry(_GEOMETRY_RADIUS, _GEOMETRY_RADIUS, _GEOMETRY_RADIUS);
        geometry.scale(-1, 1, 1);
        // equirect: this._scene.add(this._mesh = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial()));
        this._scene.add(this._mesh = new THREE.Mesh(geometry, []));

        this._frustum = new THREE.Frustum();
    }

    private _onSwiping(e: SwiperEventObj) {
        this._camLookValues.newLon += e.velocity.x * .25;
        this._camLookValues.newLat += e.velocity.y * -.25;
    }

    private _resize() {
        if (!this.enabled)
            return;

        this._renderer.setPixelRatio(window.devicePixelRatio);
        this._camera.aspect = this._cont.offsetWidth / this._cont.offsetHeight;
        this._camera.updateProjectionMatrix();
        this._renderer.setSize(this._cont.offsetWidth, this._cont.offsetHeight);

        this._canvasHalfSize = {
            x: .5 * this._renderer.domElement.width / window.devicePixelRatio,
            y: .5 * this._renderer.domElement.height / window.devicePixelRatio
        }

        this._tick(true);
    }

    private _tick(force: boolean = false) {
        if (!force && !this.enabled)
            return;

        if (!force && this._camLookValues.lon == this._camLookValues.newLon &&
            this._camLookValues.lat == this._camLookValues.newLat)
            return;

        this._camLookValues.lon = appUtils.lerp(this._camLookValues.lon, this._camLookValues.newLon, .1);
        this._camLookValues.lat = appUtils.lerp(this._camLookValues.lat, this._camLookValues.newLat, .1);

        if (force || Math.abs(this._camLookValues.lon - this._camLookValues.newLon) < .05)
            this._camLookValues.lon = this._camLookValues.newLon;
        if (force || Math.abs(this._camLookValues.lat - this._camLookValues.newLat) < .05)
            this._camLookValues.lat = this._camLookValues.newLat;

        this._camLookValues.lat = Math.max(-85, Math.min(85, this._camLookValues.lat));
        this._camera.lookAt(this._getVector3FromLatLon(this._camLookValues.lon, this._camLookValues.lat));

        this._renderer.render(this._scene, this._camera);

        this._frustum.setFromProjectionMatrix(this._camera.projectionMatrix.clone().multiply(this._camera.matrixWorldInverse));
        this._setPins();
        // this._testPin(); // TODO release comment // INFO pin place helper
    }

    private _getVector3FromLatLon(lon: number, lat: number, radius: number = _GEOMETRY_RADIUS) {
        const phi = THREE.MathUtils.degToRad(90 - lat),
            theta = THREE.MathUtils.degToRad(lon);

        return new THREE.Vector3(
            radius * Math.sin(phi) * Math.cos(theta),
            radius * Math.cos(phi),
            radius * Math.sin(phi) * Math.sin(theta)
        );
    }

    private _getScreenPos(pos: THREE.Vector3): IPoint {
        var v = pos.clone();
        v.project(this._camera);
        return {
            x: (v.x * this._canvasHalfSize.x) + this._canvasHalfSize.x,
            y: - (v.y * this._canvasHalfSize.y) + this._canvasHalfSize.y
        };
    }

    private _initSpeechRecognition() {
        (this._btnSpeech = this._cont.querySelector(".btn-speech")!)
            .addEventListener("click", () => {
                if (this._btnSpeech.classList.contains("playing")) this._stopSpeech();
                else this._speechRecognition.start();
            });
        this._btnSpeech.style.display = 'webkitSpeechRecognition' in window ? "" : "none";

        if (this._btnSpeech.style.display != "none") {
            this._speechRecognition = new window["webkitSpeechRecognition"] as SpeechRecognition;
            this._speechRecognition.lang = "tr-TR";

            this._speechRecognition.onstart = e => {
                this._btnSpeech.classList.add("playing");
            };
            this._speechRecognition.onend = e => {
                this._btnSpeech.classList.remove("playing");
            };
            this._speechRecognition.onerror = console.error;
            this._speechRecognition.onresult = e => this._onSpeechResult(e);
        }
    }

    private _stopSpeech() {
        if (!this._speechRecognition)
            return;
        this._speechRecognition.stop();
    }

    private _onSpeechResult(e: SpeechRecognitionEvent) {
        if (e.results.length < 1)
            return;

        const transcript = e.results[0][0].transcript.toLocaleLowerCase("tr-TR").replace(/[\.]/g, "");
        for (let i = 0; i < this._data.pinsInterior.length; i++) {
            if (this._data.pinsInterior[i].speechKeywords.indexOf(transcript) > -1) {
                this.selectPinById(this._data.pinsInterior[i].id);
                return;
            }
        }
    }

    private _onViewShow() {
        if (this._selectedPin) {
            this._selectedPin.dom.style.zIndex = "11";

            if (Math.abs(this._selectedPin.data.coords.x - this._camLookValues.lon) > 45 ||
                Math.abs(this._selectedPin.data.coords.y - this._camLookValues.lat) > 45) {
                this._camLookValues.newLon = this._selectedPin.data.coords.x;
                this._camLookValues.newLat = this._selectedPin.data.coords.y;
            }
        }
    }

    private _onViewHided() {
        if (this._selectedPin)
            this._selectedPin.dom.style.zIndex = "";

        this._selectedPin = undefined;
    }

    selectPinById(id: string) {
        if (this._selectedPin && this._selectedPin.data.id == id)
            return;

        const data = this._data.pinsInterior.find(pin => pin.id == id)!,
            dom = this._pinCont.querySelector<HTMLLIElement>(`[data-id=${id}]`)!;

        if (appUtils.nullOrEmpty(data))
            return;

        if (!this._selectedPin) {
            this._selectPin(dom, data);
            return;
        }

        this._onViewHided();
        this._selectedPin = { dom, data };
        this._onViewShow();
    }

    private _selectPin(pinDom: HTMLElement, data: TypeDataCarPin) {
        this._selectedPin = { dom: pinDom, data };
        app.ui.views.showView(UIViewType.CarSpecs);
    }

    private _testBox: THREE.Mesh;
    private _testLiElm: HTMLLIElement;
    private _testPin() {
        if (!this._testBox) {
            this._scene.add(this._testBox = new THREE.Mesh(new THREE.SphereGeometry(.2), new MeshBasicMaterial({color: 0xfff000})));
            /* const v = this._getVector3FromLatLon(53, -27, 10);
            this._testBox.position.set(v.x, v.y, v.z); */

            /* this._testLiElm = document.createElement("li");
            this._testLiElm.classList.add("button");
            this._testLiElm.innerHTML = '<ul class="effect-water"><li></li><li></li><li></li></ul>';
            this._pinCont.appendChild(this._testLiElm);  */
            this._cont.addEventListener("click", e => {
                let v = this._camera.getWorldDirection(this._camera.position);
                let lon = -(Math.atan2(-v.z, -v.x)) - Math.PI / 2;
                if (lon < - Math.PI) lon += Math.PI * 2;
                var p = new THREE.Vector3(v.x, 0, v.z);
                let lat = Math.acos(p.normalize().dot(v));
                if (v.y < 0) lat *= -1;
                console.log(90 - THREE.MathUtils.radToDeg(lon), THREE.MathUtils.radToDeg(lat));
                v = this._getVector3FromLatLon(90 - THREE.MathUtils.radToDeg(lon), THREE.MathUtils.radToDeg(lat), 10);
                this._testBox.position.set(v.x, v.y, v.z);
            });
        }/*  else {
            if (this._checkVector3Frustum(this._testBox.position)) {
                const pos = this._getScreenPos(this._testBox.position);
                this._testLiElm.style.left = pos.x + "px";
                this._testLiElm.style.top = pos.y + "px";
                this._testLiElm.style.display = "";
            } else
                this._testLiElm.style.display = "none";
        } */
    }

    private _setPins(force: boolean = false) {
        if (!this.enabled)
            return;

        let pinDom: HTMLLIElement;
        if (this._pinCont.children.length < 1 && this._data.pinsInterior.length > 0) {
            this._data.pinsInterior.forEach(pin => {
                pinDom = document.createElement("li");
                pinDom.classList.add("button");
                pinDom.setAttribute("data-id", pin.id);
                pinDom["coords"] = pin.coords;
                pinDom.innerHTML = '<ul class="effect-water"><li></li><li></li><li></li></ul>';
                pinDom.addEventListener("click", e => this._selectPin(e.currentTarget as HTMLElement, pin));
                this._pinCont.appendChild(pinDom);
            });
        }

        for (let i = 0, pos: IPoint, v: Vector3; i < this._pinCont.children.length; i++) {
            pinDom = this._pinCont.children[i] as HTMLLIElement;
            v = this._getVector3FromLatLon(pinDom["coords"].x, pinDom["coords"].y);
            if (this._frustum.containsPoint(v)) {
                const pos = this._getScreenPos(v);
                pinDom.style.left = pos.x + "px";
                pinDom.style.top = pos.y + "px";
                pinDom.style.display = "";
            } else if (pinDom.style.display != "none")
                pinDom.style.display = "none";
        }
    }
}