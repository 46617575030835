import { EventEmitter } from "eventemitter3";
import { app } from "..";
import UIMenuViewColors from "./uiMenuViewColors";
import UIMenuViewGeneralInfo from "./uiMenuViewGeneralInfo";
import UIMenuViewPriceInfo from "./uiMenuViewPriceInfo";
import UIView from "./uiView";
import UIViewCarSpecs from "./uiViewCarSpecs";
import UIViewGallery from "./uiViewGallery";
import UIViewVideo from "./uiViewVideo";
import UIViewVideoChat from "./uiViewVideoChat";

export enum UIViewType {
    None = "",
    Info = "info",
    Colors = "colors",
    CarSpecs = "carspecs",
    Broshure = "broshure",
    OtherPackages = "otherpackages",
    Gallery = "gallery",
    Video = "video",
    Lights = "lights",
    Link = "link",
    Appointment = "appointment",
    PrevCar = "prevcar",
    NextCar = "nextcar",
    Ambience = "ambience",
    GeneralInfo = "generalinfo",
    PriceInfo = "priceinfo",
    VideoChat = "videochat",
}

export enum UIViewsEvents {
    None = "none",
    ViewShow = "viewshow",
    ViewShowed = "viewshowed",
    ViewHideded = "viewhided"
}

export default class UIViews extends EventEmitter<UIViewsEvents> {

    private _cont: HTMLElement;
    private _views: { [type: string]: UIView } = {};
    private _currentMenuView: UIView | null;
    private _animating: boolean = false;

    get cont() { return this._cont; }

    constructor() {
        super();
        this._cont = app.ui.cont.querySelector('.views')!;
    }

    init() {
        this._views[UIViewType.Colors] = new UIMenuViewColors();
        this._views[UIViewType.GeneralInfo] = new UIMenuViewGeneralInfo();
        this._views[UIViewType.PriceInfo] = new UIMenuViewPriceInfo();
        this._views[UIViewType.CarSpecs] = new UIViewCarSpecs();
        this._views[UIViewType.Gallery] = new UIViewGallery();
        this._views[UIViewType.Video] = new UIViewVideo();
        this._views[UIViewType.VideoChat] = new UIViewVideoChat();
    }

    hide() {
        this.hideView();
        this._cont.style.display = "none";
    }

    showView(type: UIViewType) {
        if (this._animating || !this._views[type])
            return;

        this._animating = true;

        this._currentMenuView = this._views[type];
        switch (type) {
            default:
                break;
        }

        this._cont.style.display = "block";
        app.ui.carSubMenu.toggleButtonsEnable(type);
        this._currentMenuView.show(() => {
            this._animating = false;
            this.emit(UIViewsEvents.ViewShowed);
        });

        this.emit(UIViewsEvents.ViewShow);
    }

    hideView(type: UIViewType = UIViewType.None) {
        if (this._animating)
            return;

        app.ui.carSubMenu.toggleButtonsEnable();

        if (this._currentMenuView) {
            this._animating = true;

            const onComplete = () => {
                this._animating = false;
                this.emit(UIViewsEvents.ViewHideded);
            };

            if (type != UIViewType.None)
                this._views[type].hide(onComplete);
            else
                this._currentMenuView.hide(onComplete);
        }

        this._currentMenuView = null;
    }
}