import anime from "animejs";
import { app } from "..";
import UIView from "./uiView"
import { UIViewType } from "./uiViews";

enum AssistboxEventType {
    ASSISTBOX_CONNECTED = "ASSISTBOX_CONNECTED",
    ASSISTBOX_POLICY_DENIED = "ASSISTBOX_POLICY_DENIED",
    ASSISTBOX_OPEN_CAMERA = "ASSISTBOX_OPEN_CAMERA",
    ASSISTBOX_CLOSE_CAMERA = "ASSISTBOX_CLOSE_CAMERA",
    ASSISTBOX_START_MEETING = "ASSISTBOX_START_MEETING",
    ASSISTBOX_CALL_ENDED = "ASSISTBOX_CALL_ENDED",
    ASSISTBOX_PICTURE_IN_PICTURE_STARTED = "ASSISTBOX_PICTURE_IN_PICTURE_STARTED",
    ASSISTBOX_PICTURE_IN_PICTURE_ENDED = "ASSISTBOX_PICTURE_IN_PICTURE_ENDED",
    ASSISTBOX_PICTURE_IN_PICTURE_NOT_SUPPORTED = "ASSISTBOX_PICTURE_IN_PICTURE_NOT_SUPPORTED",
    ASSISTBOX_SCREEN_SHARE_STARTED = "ASSISTBOX_SCREEN_SHARE_STARTED",
    ASSISTBOX_START_SCREEN_SHARE_ERROR = "ASSISTBOX_START_SCREEN_SHARE_ERROR",
    ASSISTBOX_SCREEN_SHARE_STOPPED = "ASSISTBOX_SCREEN_SHARE_STOPPED",
    ASSISTBOX_STOP_SCREEN_SHARE_ERROR = "ASSISTBOX_STOP_SCREEN_SHARE_ERROR",
}

type AssistboxEvent = Event & {
    data: {
        event: AssistboxEventType
    }
}

const API_KEY = "r8DRm2M08sLlvxqCkStsu3kzj5DJw7aZ";
const QUEUE_CODE = "PEUGEOT-Peugeo-0e8dfad3-1a72-4a37-870e-08220d0dadda";
const IFRAME_SRC_PARAMS = `iFrame=true&lang=tr&showContactForm=false&countryCode=tr&checkOnlineAgents=true`;
const IFRAME_SRC = `https://api.assistbox.io/rest/api/embedService/assistbox.c2c.embed.bundle.js?queueCode=${QUEUE_CODE}&apiKey=${API_KEY}&endpoint=https%3A%2F%2Fgo.assistbox.io&apiEndpoint=https%3A%2F%2Fapi.assistbox.io&${IFRAME_SRC_PARAMS}`;
const API_AVAILABLE_AGENTS = `https://go.assistbox.io/rest/api/queueInfoService/getAvailableAgentCount/${QUEUE_CODE}`
const API_ONLINE_AGENTS = `https://go.assistbox.io/rest/api/queueInfoService/getOnlineAgentCount/${QUEUE_CODE}`

export class UIVideoChatButton {
    private _elm: HTMLDivElement;
    private _isVisible = false;

    constructor() {
        this._elm = document.getElementById("menu-videochat") as HTMLDivElement;
        this._elm.querySelectorAll(':scope>button').forEach((btn) =>
            btn.addEventListener("click", () => {
                app.ui.views.showView(UIViewType.VideoChat);
            }));
        this._elm.querySelector(':scope .left>a')!.addEventListener("click", (e) => {
            e.stopPropagation();
            e.preventDefault();
            (e.target as HTMLElement).parentElement!.style.display = 'none';
        });
    }

    show(delay?: number) {
        clearTimeout(this._tOutCheckVisibility);
        this._tOutCheckVisibility = setTimeout(() => this._checkVisibility(), delay);
    }

    private _tOutCheckVisibility;
    private _checkVisibility() {
        clearTimeout(this._tOutCheckVisibility);
        this._getAvailableAgentCount().then((val) => {

            const isVisible = val > 0/* && !app.isMobile.any */;
            this._elm.style.display = isVisible ? 'flex' : 'none';
            if (isVisible == true && isVisible != this._isVisible)
                anime({
                    targets: this._elm,
                    opacity: [0, 1], easing: 'linear',
                    duration: 350,
                });
            this._isVisible = isVisible;

            // this._tOutCheckVisibility = setTimeout(() => this._checkVisibility(), 2500);
        });
    }

    private _isFetching = false
    private async _getAvailableAgentCount() {
        if (this._isFetching) return 0;

        this._isFetching = true;
        try {
            const res = await fetch(API_AVAILABLE_AGENTS, { headers: { "Api-Key": API_KEY } });
            const data = await res.json();
            this._isFetching = false;
            return data || 0;
        } catch {
            this._isFetching = false;
            return 0;
        }
    }
}

export default class UIViewVideoChat extends UIView {

    static apiKey: string = API_KEY;

    private _iframe: HTMLIFrameElement;

    constructor() {
        super(app.ui.views.cont.querySelector("." + UIViewType.VideoChat)!, UIViewType.VideoChat, 'flex');

        this._iframe = this._cont.querySelector('iframe')!
        window.addEventListener('message', (e) => this._onMessage(e as AssistboxEvent));
    }

    protected override _onShow(complete: Function, endDelay?: number): void {

        this._iframe.src = IFRAME_SRC

        // ga && ga("send", "pageview", "canligorusme");
        gtag && gtag('event', 'page_view', { page_title: 'canligorusme' })
        app.urlParams.setSearchParam("overlay", "canligorusme")

        super._onShow(complete, endDelay)
    }

    hide(complete: Function): void {
        const _completeFn = () => {
            this._iframe.src = ""
            complete?.()
        }
        app.urlParams.setSearchParam("overlay")
        super.hide(_completeFn)
    }

    private _onMessage(e: AssistboxEvent) {
        if (e.data.event == AssistboxEventType.ASSISTBOX_CALL_ENDED) {
            app.ui.views.hideView(this._type);
        }
    }
}