import anime from "animejs";
import { app, YOUTUBE_IMG_LINK } from "..";
import { UIEvents } from "../ui";
import Gallery, { GalleryEvents } from "./gallery";
import UIView from "./uiView";
import { UIViewType } from "./uiViews";

export default class UIViewVideo extends UIView {
    private _title: HTMLElement;
    private _galleryDom: HTMLElement;
    private _gallery: Gallery;
    private _pagerDom: HTMLUListElement;

    constructor() {
        super(app.ui.views.cont.querySelector("." + UIViewType.Video)!, UIViewType.Video, "flex");

        this._title = this._cont.querySelector(":scope>h1")!;
        this._galleryDom = this._cont.querySelector(":scope>div.gallery-module")!;
        this._pagerDom = this._cont.querySelector(":scope>div.gallery-module>div.pager-video>ul")!;

        app.ui.addListener(UIEvents.CarChanged, () => this._onCarChanged());
    }

    protected _onShow(complete: Function, endDelay?: number): void {
        super._onShow(complete, endDelay);
        this._animChildrenShared(this._cont.querySelectorAll(":scope>*:not(button)"));
    }

    hide(complete: Function): void {
        super.hide(complete);
        this._gallery.enabled = false;
    }

    private _onCarChanged() {
        if (!app.ui.car)
            return;

        this._gallery = new Gallery(this._galleryDom,
            {
                prefix: '',
                items: app.ui.car.data.videos
            }).on(GalleryEvents.IndexChanged, () => this._onIndexChanged());

        this._initPager();
        this._onIndexChanged();
    }

    private _initPager() {
        this._pagerDom.innerHTML = "";

        let li: HTMLLIElement;
        app.ui.car!.data.videos.forEach((item, index) => {
            li = document.createElement("li");
            li.innerHTML = `<img src="${YOUTUBE_IMG_LINK + item.source}/maxresdefault.jpg" /><span>${item.title}</span>`;
            li.addEventListener("click", () => this._gallery.setIndex(index));
            this._pagerDom.appendChild(li);
        });
    }

    private _onIndexChanged() {
        this._title.innerText = app.ui.car!.data.videos[this._gallery.index].title || app.ui.car!.data.title;

        if (this._gallery.prevIndex > -1)
            (this._pagerDom.children[this._gallery.prevIndex] as HTMLLIElement).classList.remove("selected");

        (this._pagerDom.children[this._gallery.index] as HTMLLIElement).classList.add("selected");
    }
}