import anime from "animejs";
import { app } from "..";
import UIView from "./uiView";
import { UIViewType } from "./uiViews";
import UIMenuViewGeneralInfo from "./uiMenuViewGeneralInfo";

export default class UIMenuViewPriceInfo extends UIMenuViewGeneralInfo {

    constructor() {
        super(app.ui.views.cont.querySelector(".menu-price-info") as HTMLElement, UIViewType.PriceInfo);
    }
}