import { Platform } from "./platform";
import isMobile from 'ismobilejs';
import UI from "./ui";
import { EventEmitter } from "eventemitter3";
import Loader, { LoaderEvents } from "./loader";
import URLParams from "./utils/urlParams";

export enum AppEvents {
    Tick = "tick",
    Resize = "resize"
}

export default class App extends EventEmitter<AppEvents> {

    private _isMobile = isMobile(window.navigator);
    private _platform: Platform;
    private _ui: UI;
    private _loader: Loader;
    private _templates: HTMLElement;
    private _cont: HTMLElement;
    private _carsMainCont: HTMLElement;
    private _urlParams = new URLParams();

    get isMobile() { return this._isMobile; }
    get platform() { return this._platform; }
    get ui() { return this._ui; }
    get loader() { return this._loader; }
    get cont() { return this._cont; }
    get urlParams() { return this._urlParams; }

    constructor() {
        super();

        this._cont = document.getElementById('main-container')!;
        this._carsMainCont = document.getElementById('cars-main-container')!;
        this._loader = new Loader();

        window.addEventListener('load', () => {
            if (window["Jetlink"])
                document.body.classList.add("has-chat-bot");

            this._createTemplates();

            this._loader.hideUI();

            (this._platform = new Platform()).init();
            (this._ui = new UI()).init();

            window.addEventListener("resize", (e) => this._onWinResize(e));
            this._onWinResize();
            this.sendToGTM();
        });
    }

    startEnterLoad() {
        this._tryAndOpenFullscreen();
        window.addEventListener("click", (e) => this._tryAndOpenFullscreen());

        this._loader.load(false).once(LoaderEvents.Complete, () => {
            this._carsMainCont.style.display = "block";

            this.once(AppEvents.Tick, () => {
                this._onWinResize();

                this._loader.hideUI();
                this._carsMainCont.style.opacity = "1";
                this._platform.playStartAnim();
                this._ui.playStartAnim();
            })._tick();
        });
    }

    private _tick() {
        this.emit(AppEvents.Tick);
        window.requestAnimationFrame(() => this._tick());
    }

    private _onWinResize(e?: UIEvent) {
        const h = this._carsMainCont.offsetHeight;
        this._carsMainCont.style.width = h + "px";
        this._carsMainCont.style.marginLeft = window.innerWidth * .5 - h * .5 + "px";

        this.emit(AppEvents.Resize, e);
    }

    private _tryAndOpenFullscreen() {
        if (this._isMobile.phone && document.fullscreenEnabled)
            document.body.requestFullscreen();
    }

    cloneTemplate(id: string) {
        const template = this._templates.querySelector(`[data-template-id="${id}"]`)!.cloneNode(true) as HTMLElement;
        delete template.dataset["template-id"];
        return template;
    }

    sendToGTM(pageName: string = "home") {
        const pageObj = {
            "brand": "peugeot",
            "virtualPageURL": 'home',
            "pageName": pageName,
            "language": "tr",
            "country": "tr",
            "siteTypeLevel1": "kpp",
            "siteTypeLevel2": "showroom",
            "siteOwner": "local",
            "siteTarget": "B2C",
            "pageCategory": "form page",
            "siteFamily": "new cars",
            "formsName": "offer request"
        };
        (window["dataLayer"] || []).push(pageObj);
    }

    private _createTemplates() {
        this._templates = document.getElementById("templates")!;
        document.querySelectorAll<HTMLElement>("[data-template]").forEach(item => {
            console.log(item);
            const template = this._templates.querySelector(`[data-template-id="${item.dataset["template"]}"]`)!;
            let i;
            for (i = 0; i < template.children.length; i++)
                item.appendChild(template.children[i].cloneNode(true));

            for (i = 0; i < template.attributes.length; i++) {
                if (template.attributes[i].name != "data-template-id" &&
                    template.attributes[i].name != "class")
                    item.setAttribute(template.attributes[i].name, template.attributes[i].value);
            }
            template.classList.forEach(className => item.classList.add(className));
        });
    }
}