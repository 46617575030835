import { EventEmitter } from "eventemitter3";
import { app } from ".";
import UICarSubMenu from "./entities/uiCarSubMenu";
import UISecCarLocked from "./entities/uiSecCarLocked";
import UISecIntro from "./entities/uiSecIntro";
import UIViews, { UIViewType } from "./entities/uiViews";
import UISecCar from "./entities/uiSecCar";
import UISecEntrance from "./entities/uiSecEntrance";
import { DataCarList } from "./data";
import anime from "animejs";
import { LoaderEvents } from "./loader";
import { UIVideoChatButton } from "./entities/uiViewVideoChat";

export enum UIEvents {
    None = "",
    CarChanged = "carchanged",
}

export interface IUISec {
    show(delay?: number, complete?: Function): number
    hide(delay?: number, complete?: Function): number
}

export default class UI extends EventEmitter<UIEvents> {
    private _cont: HTMLElement;
    private _intro: UISecIntro;
    private _currentCar: UISecCar | null;
    private _carSecList: UISecCar[];
    private _carSubMenu: UICarSubMenu;
    private _views: UIViews;
    private _header: HTMLElement;
    private _entrance: UISecEntrance;
    private _btnMenuBack: HTMLElement;
    private _headerTypeBtns: NodeListOf<HTMLButtonElement>;
    private _videoChatBtn: UIVideoChatButton;

    get cont() { return this._cont; }
    get carSubMenu() { return this._carSubMenu; }
    get views() { return this._views; }
    get car() { return this._currentCar; }

    constructor() {
        super();
    }

    init() {
        this._cont = document.getElementById('ui-container')!;
        this._header = this._cont.querySelector(":scope header")!;

        this._entrance = new UISecEntrance();
        this._intro = new UISecIntro();
        this._carSubMenu = new UICarSubMenu();
        this._views = new UIViews();

        this._carSecList = [];
        DataCarList.forEach((v, i) => this._carSecList.push(v ? new UISecCar(i, v) : new UISecCarLocked(i)));

        (this._btnMenuBack = document.getElementById("menu-back-to-showroom")!)
            .addEventListener("click", () => app.platform.zoomOut());

        this._videoChatBtn = new UIVideoChatButton();

        (this._headerTypeBtns = this._header.querySelectorAll('[data-type="link"]')!)
            .forEach(item => {
                item.addEventListener('click', () => app.ui.carSubMenu.onBtnClick(item as HTMLElement));
            });

        this._views.init();
        if (app.urlParams.carPage)
            app.startEnterLoad();
        else
            this._entrance.show();

        this._videoChatBtn.show(500);
    }

    showHideHeaderBtns(hide: boolean = false) {
        this._btnMenuBack.style.display = hide ? "none" : "block";

        this._headerTypeBtns.forEach(item => item.style.display = hide ? 'none' : 'inline-block');

        const assistBox = document.getElementById('assistboxConnectButton');
        if (hide)
            assistBox?.classList.remove('show')
        else
            assistBox?.classList.add('show')

    }

    playStartAnim() {
        this._header.style.display = "block";
        this._entrance.hide();
        this._intro.show(1600);
    }

    changeCarSec(index: number, waitLoad?: boolean) {
        let delay = 0;

        const prevCar = this._currentCar;
        if (index > -1) {

            if (prevCar) {
                this._views.hide();
                prevCar.hide();
            } else {
                this._intro.hide(50);
                this._carSubMenu.show(350);
            }

            this._currentCar = this._carSecList[index];
            app.urlParams.setCarPage(this._currentCar.data.urlPageId);

            if (waitLoad)
                app.loader.once(LoaderEvents.Complete, () => this._showCurrentCar());
            else
                this._showCurrentCar();

        } else {
            delay = this._currentCar?.hide() || 0;
            this._currentCar = null;
            app.urlParams.clearCarPage();

            this._views.hide();
            this._carSubMenu.hide();
            this._intro.show(delay + 350);
        }

        this.emit(UIEvents.CarChanged, prevCar, this._currentCar);
        return delay;
    }

    private _showCurrentCar() {
        this._currentCar?.show(600, () => anime({
            targets: this._header.querySelector(':scope>div'),
            opacity: [0, 1], easing: 'linear', duration: 350,
        }));
    }
}