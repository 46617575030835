import { IUISec } from "../ui";
import UISecCar from "./uiSecCar";

export default class UISecCarLocked extends UISecCar {
    constructor(index: number) {
        super(index);
    }

    show() { return 0; }
    hide() { return 0; }
}