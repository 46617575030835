import anime from "animejs";
import { app } from "..";
import { AppEvents } from "../app";
import { UIEvents } from "../ui";
import { CarExteriorEvents } from "./carExterior";
import UISecCar from "./uiSecCar";
import UIView from "./uiView";
import { UIViewType } from "./uiViews";

export default class UIMenuViewColors extends UIView {
    private _ul: HTMLUListElement;
    private _eventCarColorChange: any;

    constructor() {
        super(app.ui.views.cont.querySelector(".menu-colors")!, UIViewType.Colors);

        this._ul = this._cont.querySelector(":scope > ul") as HTMLUListElement;

        app.ui.on(UIEvents.CarChanged, this._onCarChanged.bind(this));
    }

    show(complete: Function) {
        super.show(complete);
    }

    protected _onShow(complete: Function) {
        super._onShow(complete, 250);

        anime({
            targets: this._ul.children,
            opacity: [0, 1], translateY: ["15%", 0],
            duration: 350, easing: "easeOutQuad",
            delay: anime.stagger(40, { start: 100, from: "last" })
        });

    }

    private _onCarChanged(prevCar?: UISecCar, currentCar?: UISecCar) {
        this._ul.innerHTML = "";

        prevCar?.exterior.off(CarExteriorEvents.ColorChange, this._eventCarColorChange);
        currentCar?.exterior.on(CarExteriorEvents.ColorChange, this._eventCarColorChange = this._onCarColorChanged.bind(this));

        if (!app.ui.car)
            return;

        app.ui.car.data.colors.forEach((color, index) => {
            const li = document.createElement("li") as HTMLLIElement;
            li.innerHTML = `<span style="background-color:${color.colorHex}"></span> ` + color.title;
            if (color.featured) li.classList.add("selected");
            else if (color.disabled) li.classList.add("disabled");

            if (color.disabled != true)
                li.addEventListener("click", () => {
                    app.ui.car!.exterior.changeColor(index);
                    app.ui.views.hideView();
                });

            this._ul.appendChild(li);
        });
    }

    private _onCarColorChanged() {
        for (let i = 0; i < this._ul.children.length; i++) {
            this._ul.children[i].classList.remove("selected");
            if (app.ui.car!.exterior.colorIndex == i)
                this._ul.children[i].classList.add("selected");
        }
    }
}