import { IDataCar } from ".";

export default {
    id: "car_408",
    urlPageId: "408",
    title: "PEUGEOT 408",
    shortTitle: "408",
    colors: [
        { colorHex: "#256e91", title: "Blue Obsession (Mavi)", dir: "mavi", featured: true },
        { colorHex: "#2e2f32", title: "Gri (Titanyum)", dir: "gri" },
        { colorHex: "#7b7d84", title: "Gri (Tekno)", dir: "gritekno" },
        { colorHex: "#851a26", title: "Kırmızı", dir: "kirmizi" },
        { colorHex: "#222222", title: "Siyah", dir: "siyah" },
        { colorHex: "#fff", title: "Beyaz", dir: "beyaz" },
    ],
    infoLink: "https://www.peugeot.com.tr/peugeot-modelleri/408.html",
    otherPackagesLink: "https://www.peugeot.com.tr/peugeot-modelleri/408.html",
    broshureLink: "https://www.peugeot.com.tr/content/dam/peugeot/turkey/b2c/bro%C5%9F%C3%BCrler/2023/mart/408_TeknikBrosuru.pdf",
    appointmentLink: '408',
    galleryPhotos: [
        { source: "gallery_0.jpg" }, { source: "gallery_1.jpg" }, { source: "gallery_2.jpg" },
        { source: "gallery_3.jpg" }, { source: "gallery_4.jpg" }, { source: "gallery_5.jpg" },
        { source: "gallery_6.jpg" }, { source: "gallery_7.jpg" }, { source: "gallery_8.jpg" },
    ],
    videos: [
        { title: "Yeni PEUGEOT 408 - Her Açıdan Göz Alıcı", type: "youtube", source: "DRgYaEaPzzA" },
        { title: "Her Açıdan Göz Alıcı: Yeni PEUGEOT 408", type: "youtube", source: "2TujYYHumGc" },
    ],
    interiorImageNames: [
        "interior_0", "interior_1", "interior_2", "interior_3", "interior_4", "interior_5",
    ],
    pinsInterior: [
        {
            id: "wheel-i", coords: { x: 53.63, y: -20.5 },
            speechKeywords: "direksiyon",
        }, {
            id: "frontseat", coords: { x: 18.14, y: -64.7 },
            speechKeywords: "koltuk, ön koltuk",
        }, {
            id: "sunroof", coords: { x: -89, y: 38.5 },
            speechKeywords: "açılır cam tavan, tavan, cam tavan",
        }, {
            id: "backseat", coords: { x: -89, y: -39.25 },
            speechKeywords: "arka yaşam alanı",
        }, {
            id: "torpido", coords: { x: 120, y: -21.5 },
            speechKeywords: "torpido, torpido gözü",
        }, {
            id: "cockpit", coords: { x: 67.5, y: -10 },
            speechKeywords: "ön gösterge paneli, gösterge paneli",
        }, {
            id: "trunk-i", coords: { x: -89, y: -10 },
            speechKeywords: "bagaj, elektrikli bagaj",
        }, {
            id: "screen", coords: { x: 92.5, y: -17.5 },
            speechKeywords: "dokunmatik multimedya ekranı, multimedya",
        }, {
            id: "middleconsole", coords: { x: 87, y: -44.75 },
            speechKeywords: "orta konsol, e-toggle, kablosuz şarj",
        }, {
            id: "modes", coords: { x: 82.5, y: -39 },
            speechKeywords: "sürüş modları, spor sürüş modu",
        },
    ],
    featuredPinFrameIndex: 1,
    pinFrames: [
        { index: 0, pins: [{ id: "frontgrid", posXPerc: 64.34, posYPerc: 61 }, { id: "mirrors", posXPerc: 35.57, posYPerc: 45.36 }, { id: "lights", posXPerc: 46.68, posYPerc: 55.86 },] },
        { index: 1, pins: [{ id: "wheel", posXPerc: 47.68, posYPerc: 69.32 }, { id: "frontgrid", posXPerc: 76.01, posYPerc: 60.67 }, { id: "mirrors", posXPerc: 40.12, posYPerc: 45.02 }, { id: "bodyprotection", posXPerc: 34.68, posYPerc: 64.83 }, { id: "lights", posXPerc: 58.68, posYPerc: 56.17 },] },
        { index: 10, pins: [{ id: "mirrors", posXPerc: 67.23, posYPerc: 44.19 }, { id: "backbumper", posXPerc: 34.68, posYPerc: 66.32 }, { id: "catear", posXPerc: 51.9, posYPerc: 35.71 }, { id: "backlights", posXPerc: 52.01, posYPerc: 47.02 }, { id: "trunkline", posXPerc: 35.01, posYPerc: 47.69 },] },
        { index: 11, pins: [{ id: "backbumper", posXPerc: 50.12, posYPerc: 65.99 }, { id: "catear", posXPerc: 60.68, posYPerc: 34.88 }, { id: "backlights", posXPerc: 65.01, posYPerc: 46.86 }, { id: "trunkline", posXPerc: 50.23, posYPerc: 47.69 },] },
        { index: 12, pins: [{ id: "mirrors", posXPerc: 31.79, posYPerc: 44.53 }, { id: "backbumper", posXPerc: 65.57, posYPerc: 65.82 }, { id: "catear", posXPerc: 47.78, posYPerc: 35.71 }, { id: "backlights", posXPerc: 49.12, posYPerc: 47.52 }, { id: "trunkline", posXPerc: 65.12, posYPerc: 47.52 },] },
        { index: 13, pins: [{ id: "mirrors", posXPerc: 30.34, posYPerc: 44.86 }, { id: "backbumper", posXPerc: 78.79, posYPerc: 64.83 }, { id: "bodyprotection", posXPerc: 36.12, posYPerc: 65.32 }, { id: "catear", posXPerc: 57.79, posYPerc: 35.37 }, { id: "backlights", posXPerc: 63.9, posYPerc: 47.35 }, { id: "trunkline", posXPerc: 78.01, posYPerc: 47.35 }, { id: "wheel", posXPerc: 48.68, posYPerc: 68.99 },] },
        { index: 14, pins: [{ id: "sidelogo", posXPerc: 29.12, posYPerc: 50.35 }, { id: "mirrors", posXPerc: 30.46, posYPerc: 44.86 }, { id: "backbumper", posXPerc: 87.79, posYPerc: 63.66 }, { id: "bodyprotection", posXPerc: 39.23, posYPerc: 64.99 }, { id: "catear", posXPerc: 66.9, posYPerc: 36.04 }, { id: "backlights", posXPerc: 76.34, posYPerc: 47.19 }, { id: "trunkline", posXPerc: 87.01, posYPerc: 46.87 }, { id: "wheel", posXPerc: 58.46, posYPerc: 68.82 },] },
        { index: 15, pins: [{ id: "sidelogo", posXPerc: 29.34, posYPerc: 51.18 }, { id: "mirrors", posXPerc: 31.46, posYPerc: 45.36 }, { id: "backbumper", posXPerc: 91.01, posYPerc: 63 }, { id: "bodyprotection", posXPerc: 42.9, posYPerc: 65.66 }, { id: "catear", posXPerc: 73.23, posYPerc: 36.37 }, { id: "backlights", posXPerc: 85.79, posYPerc: 46.52 }, { id: "wheel", posXPerc: 66.9, posYPerc: 67.82 },] },
        { index: 16, pins: [{ id: "sidelogo", posXPerc: 31.34, posYPerc: 51.18 }, { id: "mirrors", posXPerc: 33.79, posYPerc: 45.19 }, { id: "backbumper", posXPerc: 90.9, posYPerc: 62.33 }, { id: "bodyprotection", posXPerc: 47.68, posYPerc: 65.82 }, { id: "catear", posXPerc: 77.79, posYPerc: 36.71 }, { id: "backlights", posXPerc: 90.12, posYPerc: 46.86 }, { id: "lights", posXPerc: 12.79, posYPerc: 53.01 }, { id: "wheel", posXPerc: 73.57, posYPerc: 67.15 },] },
        { index: 17, pins: [{ id: "lights", posXPerc: 12.68, posYPerc: 53.51 }, { id: "sidelogo", posXPerc: 34.79, posYPerc: 51.51 }, { id: "mirrors", posXPerc: 38.23, posYPerc: 45.36 }, { id: "backbumper", posXPerc: 92.01, posYPerc: 61 }, { id: "bodyprotection", posXPerc: 52.57, posYPerc: 66.16 }, { id: "catear", posXPerc: 79.57, posYPerc: 36.54 }, { id: "backlights", posXPerc: 91.68, posYPerc: 46.02 }, { id: "wheel", posXPerc: 23.01, posYPerc: 66.32 },] },
        { index: 18, pins: [{ id: "sidelogo", posXPerc: 39.9, posYPerc: 51.68 }, { id: "mirrors", posXPerc: 43.68, posYPerc: 45.52 }, { id: "bodyprotection", posXPerc: 57.12, posYPerc: 65.82 }, { id: "catear", posXPerc: 79.23, posYPerc: 37.37 }, { id: "lights", posXPerc: 16.01, posYPerc: 54.18 }, { id: "backlights", posXPerc: 89.79, posYPerc: 46.19 }, { id: "wheel", posXPerc: 28.01, posYPerc: 67.99 },] },
        { index: 19, pins: [{ id: "frontgrid", posXPerc: 10.01, posYPerc: 58.5 }, { id: "sidelogo", posXPerc: 45.46, posYPerc: 51.85 }, { id: "mirrors", posXPerc: 49.79, posYPerc: 45.69 }, { id: "bodyprotection", posXPerc: 60.68, posYPerc: 65.16 }, { id: "lights", posXPerc: 21.9, posYPerc: 55.34 }, { id: "catear", posXPerc: 77.12, posYPerc: 37.04 }, { id: "backlights", posXPerc: 85.79, posYPerc: 45.19 }, { id: "wheel", posXPerc: 34.9, posYPerc: 68.65 },] },
        { index: 2, pins: [{ id: "frontgrid", posXPerc: 84.12, posYPerc: 59.17 }, { id: "sidelogo", posXPerc: 47.9, posYPerc: 52.01 }, { id: "mirrors", posXPerc: 45.23, posYPerc: 45.02 }, { id: "bodyprotection", posXPerc: 36.23, posYPerc: 64.49 }, { id: "lights", posXPerc: 70.79, posYPerc: 56.01 }, { id: "wheel", posXPerc: 56.79, posYPerc: 69.15 },] },
        { index: 20, pins: [{ id: "frontgrid", posXPerc: 15.23, posYPerc: 59.83 }, { id: "sidelogo", posXPerc: 51.9, posYPerc: 52.01 }, { id: "mirrors", posXPerc: 55.46, posYPerc: 45.36 }, { id: "bodyprotection", posXPerc: 63.68, posYPerc: 64.49 }, { id: "lights", posXPerc: 31.68, posYPerc: 55.84 }, { id: "wheel", posXPerc: 43.68, posYPerc: 68.82 },] },
        { index: 21, pins: [{ id: "frontgrid", posXPerc: 24.57, posYPerc: 60.67 }, { id: "sidelogo", posXPerc: 57.56, posYPerc: 51.71 }, { id: "mirrors", posXPerc: 60.68, posYPerc: 45.2 }, { id: "bodyprotection", posXPerc: 64.79, posYPerc: 64.49 }, { id: "lights", posXPerc: 43.23, posYPerc: 55.67 }, { id: "wheel", posXPerc: 52.46, posYPerc: 68.65 },] },
        { index: 22, pins: [{ id: "frontgrid", posXPerc: 36.23, posYPerc: 61.33 }, { id: "mirrors", posXPerc: 64.46, posYPerc: 45.19 }, { id: "bodyprotection", posXPerc: 65.12, posYPerc: 64.49 }, { id: "lights", posXPerc: 54.9, posYPerc: 56.17 },] },
        { index: 23, pins: [{ id: "frontgrid", posXPerc: 49.57, posYPerc: 61.83 }, { id: "mirrors", posXPerc: 67.12, posYPerc: 44.69 }, { id: "lights", posXPerc: 65.68, posYPerc: 55.51 },] },
        { index: 3, pins: [{ id: "sidelogo", posXPerc: 54.23, posYPerc: 52.18 }, { id: "mirrors", posXPerc: 50.9, posYPerc: 45.36 }, { id: "bodyprotection", posXPerc: 39.46, posYPerc: 64.99 }, { id: "lights", posXPerc: 78.57, posYPerc: 54.84 }, { id: "wheel", posXPerc: 64.79, posYPerc: 68.32 },] },
        { index: 4, pins: [{ id: "sidelogo", posXPerc: 59.46, posYPerc: 51.68 }, { id: "mirrors", posXPerc: 56.57, posYPerc: 44.86 }, { id: "bodyprotection", posXPerc: 42.79, posYPerc: 65.82 }, { id: "wheel", posXPerc: 71.46, posYPerc: 67.49 },] },
        { index: 5, pins: [{ id: "backlights", posXPerc: 8.57, posYPerc: 45.86 }, { id: "sidelogo", posXPerc: 64.23, posYPerc: 51.18 }, { id: "mirrors", posXPerc: 61.23, posYPerc: 45.19 }, { id: "bodyprotection", posXPerc: 47.01, posYPerc: 66.16 }, { id: "catear", posXPerc: 19.45, posYPerc: 36.9 }, { id: "lights", posXPerc: 86.68, posYPerc: 52.85 }, { id: "wheel", posXPerc: 76.01, posYPerc: 66.32 },] },
        { index: 6, pins: [{ id: "sidelogo", posXPerc: 67.9, posYPerc: 51.18 }, { id: "mirrors", posXPerc: 65.9, posYPerc: 44.86 }, { id: "bodyprotection", posXPerc: 52.23, posYPerc: 65.99 }, { id: "backbumper", posXPerc: 7.68, posYPerc: 60.33 }, { id: "catear", posXPerc: 21.56, posYPerc: 36.55 }, { id: "backlights", posXPerc: 11.12, posYPerc: 46.86 }, { id: "wheel", posXPerc: 26.34, posYPerc: 67.15 },] },
        { index: 7, pins: [{ id: "sidelogo", posXPerc: 69.79, posYPerc: 50.52 }, { id: "mirrors", posXPerc: 68.12, posYPerc: 45.19 }, { id: "backbumper", posXPerc: 8.46, posYPerc: 63 }, { id: "bodyprotection", posXPerc: 56.79, posYPerc: 65.99 }, { id: "catear", posXPerc: 25.79, posYPerc: 36.05 }, { id: "backlights", posXPerc: 17.68, posYPerc: 47.02 }, { id: "wheel", posXPerc: 32.9, posYPerc: 68.15 },] },
        { index: 8, pins: [{ id: "sidelogo", posXPerc: 70.01, posYPerc: 50.35 }, { id: "mirrors", posXPerc: 68.9, posYPerc: 43.86 }, { id: "backbumper", posXPerc: 12.46, posYPerc: 64.33 }, { id: "bodyprotection", posXPerc: 61.01, posYPerc: 65.32 }, { id: "catear", posXPerc: 33.23, posYPerc: 35.88 }, { id: "backlights", posXPerc: 25.23, posYPerc: 46.86 }, { id: "trunkline", posXPerc: 13.01, posYPerc: 46.86 }, { id: "wheel", posXPerc: 41.68, posYPerc: 69.15 },] },
        { index: 9, pins: [{ id: "mirrors", posXPerc: 68.68, posYPerc: 44.36 }, { id: "backbumper", posXPerc: 21.9, posYPerc: 65.16 }, { id: "bodyprotection", posXPerc: 63.57, posYPerc: 64.83 }, { id: "catear", posXPerc: 42.12, posYPerc: 36.04 }, { id: "backlights", posXPerc: 37.46, posYPerc: 47.35 }, { id: "trunkline", posXPerc: 22.23, posYPerc: 47.19 }, { id: "wheel", posXPerc: 51.23, posYPerc: 69.15 },] },
    ]
} as IDataCar;